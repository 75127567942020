import {TimelineLite, Expo} from 'gsap';
import ScrollMagic from 'scrollmagic';

const home = () => {
  const span = document.querySelector('.hero__title').querySelectorAll('span');
  const intro = document.querySelector('.hero__intro');
  const mouse = document.querySelector('.hero__mouse');
  const plan = document.querySelector('.plan');
  const controllerHero = new ScrollMagic.Controller();

  const tweenTitle = new TimelineLite();
  tweenTitle
  .staggerTo(span, 0.3, {y: 0, opacity: 1, ease: Expo.ease}, 0.2, "-= 0.2")
  .to(intro, 0.3, {y: 0, opacity: 1, ease: Expo.ease}, "-= 0.2")
  .delay(0.7);

  new ScrollMagic.Scene({
    triggerElement: plan,
    offset: 0,
    reverse: true
  })
  .on('enter', event => {
     mouse.style.display = 'none';
  })
  .on('leave', event => {
    mouse.style.display = 'inherit';
  })
  .addTo(controllerHero);
}

export default home;


