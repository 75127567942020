import $ from 'jquery';

const contact = () => {
  const $name = document.querySelector('.input-name');
  const $email = document.querySelector('.input-email');
  const $message = document.querySelector('.input-message');
  const $submit = document.querySelector('.submit');
  const $mask = document.querySelector('.contact__mask');
  const $output = document.querySelector('.contact__output');

  $name.value = '';
  $email.value = '';
  $message.value = '';

  const showMask = output => {
    $mask.classList.add('visible');
    $output.classList.add('visible');
    $output.innerHTML = output;
    setTimeout(function () {
      $mask.classList.remove('visible');
      $output.classList.remove('visible');
    }, 1000);
  }

  $submit.addEventListener('click', e => {
    let output;
    e.preventDefault();
    let proceed = true;

    if($message.value.trim() === '') {
      proceed = false;
      output = 'Please, enter a message';
      showMask(output);
    }

    if($message.value.trim().length >= 1 && $message.value.trim().length < 5) {
      proceed = false;
      output = 'Your message is too short';
      showMask(output);
    }

    if($message.value.trim().length > 200) {
      proceed = false;
      output = 'Your message is too long';
      showMask(output);
    }

    if($email.value.trim() === '') {
      proceed = false;
      output = 'Please, enter your email';
      showMask(output);
    }

    let email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if( !email_reg.test($email.value.trim())) {
      proceed = false;
      output = 'Please, enter a valid email';
      showMask(output);
    }

    if($name.value.trim() === '') {
      proceed = false;
      output = 'Please, enter your name';
      showMask(output);
    }

    if($name.value.trim().length >= 1 && $name.value.trim().length < 3) {
      proceed = false;
      output = 'Your name is too short';
      showMask(output);
    }

    if($name.value.trim().length > 30) {
      proceed = false;
      output = 'Your name is too long';
      showMask(output);
    }

    if (proceed) {
      let post_data = {
        user_name: $name.value,
        user_email: $email.value,
        msg: $message.value
      };

      // console.log(post_data);

      $(document).ready(function ($) {

        $.post(
          "mailer.php",
          post_data,
          function (response) {
            if (response.type === "error") {
              output = response.text;
              console.log('error');
              showMask(output);
            } else {
              output = response.text;
              showMask(output);
              console.log('success', post_data);
              $('.input-name, .input-email, .input-message').val('');
            }
          },
          "json"
        );
      });
    }
  })
}

export default contact;
