import React, {useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import nav from '../js/nav';
import QuickContact from './QuickContact';

const Nav = () => {

  useEffect(() => {
    nav();
  }, [])

  return (
    <div className="nav">
      <div className="nav__content">
        <nav className="nav__items">
          <NavLink to="/what-we-do" className="nav__item nav__item--big">What We Do</NavLink>
          <NavLink to="/our-work" className="nav__item nav__item--big">Our Work</NavLink>
          <NavLink to="/team" className="nav__item nav__item--big" >Team</NavLink>
          <NavLink to="/" className="nav__item">Home</NavLink>
          <NavLink to="/contact" className="nav__item">Contact</NavLink>
        </nav>
        <div className="nav__contact">
          <QuickContact/>
        </div>
      </div>
      <Link to='/contact' className="nav-hero">
        <div className="nav-hero-img" data-displacement="img/displacement/noise4.jpg">
          <img src="img/pages/1.jpg" alt="Nav 1"/>
          <img src="img/pages/2.jpg" alt="Nav 2"/>
        </div>
        <button className="button-arrow-black nav-hero__cta">Start a Project<img src="../img/svg/arrow-white.svg" alt="arrow"/></button>
      </Link>
    </div>
  )
}

export default Nav;