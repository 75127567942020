import React, { useEffect } from 'react';
import scroll from '../js/scroll';
import notfound from '../js/not-found';
import Back from './Back';

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    scroll();
    notfound();
  }, [])

  return (
    <div className="not-found section light" data-site-body="not-found__inner-found">
      <div className="not-found__info wrapper">
        <h1 className="not-found__title">Oops!</h1>
        <h2 className="not-found__subtitle">This page was removed,<br/>renamed or doesn't exist.</h2>
        <Back/>
      </div>
      <div className="not-found__photo" style={{backgroundImage: "url(../img/pages/oops.jpg)"}}></div>
    </div>
  )
}

export default NotFound