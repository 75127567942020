import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import scroll from '../js/scroll';
import ourWork from '../js/our-work';
import fadein from '../js/fadein';
import Projects from '../data/projects';
import Start from './Start';

const OurWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    scroll();
    ourWork();
    fadein();
  }, [])

  return (
    <div className="our-work fade-in section light">
      <div className="our-work__inner wrapper">
       <Start />
        <div className="our-work__header">
          <div className="our-work__filters">
            <span className="our-work__filter selected" data-tag="all">ALL</span>
            <span className="our-work__filter" data-tag="brand">BRAND ID</span>
            <span className="our-work__filter" data-tag="web">WEB & APPS</span>
            <span className="our-work__filter" data-tag="etools">eTOOLS</span>
            <span className="our-work__filter" data-tag="print">PRINT</span>
            <span className="our-work__filter" data-tag="emails">EMAILS</span>
          </div>
          <div className="our-work__intro">
            <p>When a business owner trusts in us to create jaw-dropping creative that drives results, we don’t take that responsibility lightly.
            When our clients succeed, we succeed. Drill down on some of our proudest moments.</p>
          </div>
        </div>
        <div className="our-work__projects">
          { Projects.map(project => {
              return (
                <Link key={project.slug} className={`our-work__project move-up ${project.tag} ${project.tag} ${project.span}`} to={`/our-work/${project.slug}`}>
                  <h2 className={`our-work__project-title ${project.thumbColor}`}>{project.client}</h2>
                  <img className="our-work__project-image" src={project.thumb} alt="Thumbnail" />
                  <div className="our-work__project-content">
                    <h3 className="our-work__project-content-client">{project.client}</h3>
                    <h3 className="our-work__project-content-label">{project.type}</h3>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default OurWork