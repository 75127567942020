import React, { useEffect } from 'react';
import Hero from './Hero';
import Plan from './Plan';
import Testimonials from './Testimonials';
import Clients from './Clients';

import scroll from '../js/scroll';
import fadein from '../js/fadein';
import home from '../js/home';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    home();
    scroll();
    fadein();
  }, [])
  return (
    <div className="home fade-in" data-site-body="home">
      <Hero/>
      <Plan/>
      <Testimonials/>
      <Clients/>
    </div>
  )
}

export default Home;
