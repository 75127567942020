import React, { useEffect } from 'react';

import { ReactComponent as Logo } from '../svg/logo.svg';
import { ReactComponent as LogoDark } from '../svg/logo-dark.svg';
import { ReactComponent as LogoWhite } from '../svg/logo-white.svg';

import { ReactComponent as Pull } from '../svg/pull.svg';
import { ReactComponent as PullDark } from '../svg/pull-dark.svg';
import { ReactComponent as PullWhite } from '../svg/pull-white.svg';

import { ReactComponent as Hello } from '../svg/hello.svg';
import { ReactComponent as HelloDark } from '../svg/hello-dark.svg';
import { ReactComponent as HelloWhite } from '../svg/hello-white.svg';

import { withRouter } from 'react-router-dom';
import { Link  } from 'react-router-dom';
import Nav from './Nav';
import header from '../js/header'

const Header = props => {
  useEffect(() => {
   header()
  }, []);

  return (
    <header className="header" role="banner">
      <div className="header__logo">
        <Link to="/" className="header__logo-dark"><LogoDark/></Link>
        <Link to="/" className="header__logo-white"><LogoWhite/></Link>
      </div>
      <Link to="/" className="header__logo-overlay"><Logo/></Link>

      <div className="header__pull">
        <div className="header__pull-dark"><PullDark/></div>
        <div className="header__pull-white"><PullWhite/></div>
      </div>

      <div className="header__pull-overlay">
        <div className="pull-overlay-wrapper"><Pull/></div>
      </div>

      <div className="header__hello">
        <a href="mailto:hello@mwxdigitalcom" className="header__hello-dark"><HelloDark/></a>
        <a href="mailto:hello@mwxdigitalcom" className="header__hello-white"><HelloWhite/></a>
      </div>
      <a href="mailto:hello@mwxdigitalcom" className="header__hello-overlay"><Hello/></a>

      <Nav/>
    </header>
  )
}

export default withRouter(Header);

