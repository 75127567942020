import scroll from '../utils/scroll';

const ourWork = () => {
  const filters = document.querySelectorAll('.our-work__filter');
  const projects = document.querySelectorAll('.our-work__project');

  filters.forEach(filter => {
    filter.addEventListener('click', () => {
      let tag = filter.getAttribute('data-tag');

      if (tag === 'all') {
        projects.forEach(project => {
          project.classList.remove('hidden');
        });
      } else {
          projects.forEach(project => {
          project.classList.add('hidden');
        });
        let show = document.querySelectorAll(`.${tag}`);
        show.forEach(s => {
          s.classList.remove('hidden');
        })

      }

      const ourWork = document.querySelector('.our-work__projects');
      scroll(ourWork , 500, 'easeOutQuad');

      filters.forEach(filter => {
        filter.classList.remove('selected');
      })
      filter.classList.add('selected');
    })

    filter.addEventListener('mouseover', () => {
      filters.forEach(filter => {
        filter.classList.add('blur');
      })
      filter.classList.remove('blur');
    })

    filter.addEventListener('mouseout', () => {
      filters.forEach(filter => {
        filter.classList.remove('blur');
      })
    })

    filter.addEventListener('touchstart', () => {
      filters.forEach(filter => {
        filter.classList.add('blur');
      })
      filter.classList.remove('blur');
    })

    filter.addEventListener('touchend', () => {
      filters.forEach(filter => {
        filter.classList.remove('blur');
      })
    })
  })
}

export default ourWork;