import React from 'react';
import { ReactComponent as Logo } from '../svg/logo.svg';
import { Link, NavLink } from 'react-router-dom';
import QuickContact from './QuickContact';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner wrapper">
         <Link to="/" className="footer__logo logo--full-orange-white"><Logo/></Link>
          <nav className="footer__nav">
            <NavLink to="/" className="footer__nav-item">Home</NavLink>
            <NavLink to="/what-we-do" className="footer__nav-item">What We Do</NavLink>
            <NavLink to="/our-work" className="footer__nav-item">Our Work</NavLink>
            <NavLink to="/team" className="footer__nav-item">Team</NavLink>
            <NavLink to="/contact" className="footer__nav-item">Contact</NavLink>
          </nav>
          <div className="footer__contact">
            <QuickContact/>
          </div>
        <p className="footer__copy">© MediaWorx {new Date().getFullYear()}. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer