import { TimelineLite, Power1 } from 'gsap';
import ScrollMagic from 'scrollmagic';

const scroll = () => {
  const logo = document.querySelector('.header__logo');
  const start = document.querySelector('.start');
  const logoDark = document.querySelector('.header__logo-dark');
  const logoDarkSVG = document.getElementById('logo-dark');
  const pull = document.querySelector('.header__pull');
  const pullDark = document.querySelector('.header__pull-dark');
  const pullDarkSVG = document.getElementById('pull-dark');
  const hello = document.querySelector('.header__hello');
  const helloDark = document.querySelector('.header__hello-dark');
  const helloDarkSVG = document.getElementById('hello-dark');
  const sections = document.querySelectorAll('.section');
  let scrollLogoDark, scrollLogoLight, scrollPullDark, scrollPullLight, scrollHelloDark, scrollHelloLight,
      distance, logoTop, pullTop, helloTop, colorLogo1, colorLogo2, colorPull1, colorPull2, colorHello1, colorHello2,
      sectionsTopPos = [], items = [];
  const moveUp = document.querySelectorAll('.move-up');
  const moveRight = document.querySelectorAll('.move-right');
  const controllerMove = new ScrollMagic.Controller();
  const logoLetters = document.querySelectorAll('.word');
  const logoHeight = logo.clientHeight;
  const pullHeight = pull.clientHeight;
  const helloHeight = hello.clientHeight;

  const swapHeaderElements = () => {
    distance = window.pageYOffset;
    sections.forEach((section, i) => {
      sectionsTopPos[i] = section.getBoundingClientRect().top;
    });

    logoTop = logo.getBoundingClientRect().top;
    pullTop = pull.getBoundingClientRect().top;
    helloTop = hello.getBoundingClientRect().top;

    logoLetters.forEach(letter => {
      letter.style.transform = 'translateY('+(-distance * 0.3)+'px)';
    })

    if (start != null) start.style.transform = 'translateY('+(-distance * 0.3)+'px)';

    const darkLogo = (sectionTop) => {
      scrollLogoDark = sectionTop - logoTop;
      if (scrollLogoDark < 0 ) scrollLogoDark = 0;
      logoDark.style.transform = "translateY("+(scrollLogoDark)+"px)";
      logoDarkSVG.style.transform = "translateY("+(-scrollLogoDark)+"px)";
    }

    const lightLogo = (sectionTop) => {
      scrollLogoLight = sectionTop - logoTop;
      if (scrollLogoLight < 0 ) scrollLogoLight = 0;
      logoDark.style.transform = "translateY("+(scrollLogoLight - logoHeight)+"px)";
      logoDarkSVG.style.transform = "translateY("+(-scrollLogoLight + logoHeight)+"px)";
    }

    const darkPull = (sectionTop) => {
      scrollPullDark = sectionTop - pullTop;
      if (scrollPullDark < 0 ) scrollPullDark = 0;
      pullDark.style.transform = "translateY("+(scrollPullDark)+"px)";
      pullDarkSVG.style.transform = "translateY("+(-scrollPullDark)+"px)";
    }

    const lightPull = (sectionTop) => {
      scrollPullLight = sectionTop - pullTop;
      if (scrollPullLight < 0 ) scrollPullLight = 0;
      pullDark.style.transform = "translateY("+(scrollPullLight - pullHeight)+"px)";
      pullDarkSVG.style.transform = "translateY("+(-scrollPullLight + pullHeight)+"px)";
    }

    const darkHello = (sectionTop) => {
      scrollHelloDark = sectionTop - helloTop;
      if (scrollHelloDark < 0 ) scrollHelloDark = 0;
      helloDark.style.transform = "translateY("+(scrollHelloDark)+"px)";
      helloDarkSVG.style.transform = "translateY("+(-scrollHelloDark)+"px)";
    }

    const lightHello = (sectionTop) => {
      scrollHelloLight = sectionTop - helloTop;
      if (scrollHelloLight < 0 ) scrollHelloLight = 0;
      helloDark.style.transform = "translateY("+(scrollHelloLight - helloHeight)+"px)";
      helloDarkSVG.style.transform = "translateY("+(-scrollHelloLight + helloHeight)+"px)";
    }

    if ( sections[0].classList.contains('dark')) {
      lightLogo(0);
      lightPull(0);
      lightHello(0);

      colorLogo1 = (sectionTop) => {
       lightLogo(sectionTop);
      }

      colorLogo2 = (sectionTop) => {
        darkLogo(sectionTop);
      }
      colorPull1 = (sectionTop) => {
        lightPull(sectionTop);
      }
      colorPull2 = (sectionTop) => {
        darkPull(sectionTop);
      }
      colorHello1 = (sectionTop) => {
        lightHello(sectionTop);
      }
      colorHello2 = (sectionTop) => {
        darkHello(sectionTop);
      }

    } else if ( sections[0].classList.contains('light')) {
      darkLogo(0);
      darkPull(0);
      darkHello(0);

      colorLogo1 = (sectionTop) => {
        darkLogo(sectionTop);
      }
      colorLogo2 = (sectionTop) => {
        lightLogo(sectionTop);
      }
      colorPull1 = (sectionTop) => {
        darkPull(sectionTop);
      }
      colorPull2 = (sectionTop) => {
        lightPull(sectionTop);
      }
      colorHello1 = (sectionTop) => {
        darkHello(sectionTop);
      }
      colorHello2 = (sectionTop) => {
        lightHello(sectionTop);
      }
    }

    items = [
      {offset: logoTop, height: logoHeight, color1: colorLogo1, color2: colorLogo2},
      {offset: pullTop, height: pullHeight, color1: colorPull1, color2: colorPull2},
      {offset: helloTop, height: helloHeight, color1: colorHello1, color2: colorHello2}
    ]

    for (let i = 0; i < sectionsTopPos.length; i++ ) {
      items.forEach(item => {
        if ( i%2 === 0 && item.offset > sectionsTopPos[i] - item.height ){
          item.color1(sectionsTopPos[i]);
        } else if ( i%2 !== 0 && item.offset > sectionsTopPos[i] - item.height ){
          item.color2(sectionsTopPos[i]);
        } else if ( i%2 === 0 && item.offset >= sectionsTopPos[sectionsTopPos.length] - item.height ) {
          item.color1(sectionsTopPos.length)
        } else if ( item.offset >= sectionsTopPos[sectionsTopPos.length - 1 ] - item.height ) {
            if (i%2 === 0) {
              item.color1(sectionsTopPos[sectionsTopPos.length - 1 ])
            } else if (i%2 !== 0) {
              item.color2(sectionsTopPos[sectionsTopPos.length - 1 ])
            }
        }
      })
    }
  }

  swapHeaderElements();

  window.addEventListener('scroll', () => {
    swapHeaderElements();
  })

  window.addEventListener('resize', () => {
    swapHeaderElements();
  })

  moveUp.forEach(upItem => {
    const tweenUp = new TimelineLite({paused: true})
    tweenUp.fromTo(upItem, 0.7, {y: 50, opacity: 0}, {y: 0, opacity: 1, ease: Power1.easeOut });

    new ScrollMagic.Scene({
      triggerElement: upItem,
      offset: -(window.innerHeight * 0.4)
    })
    .on('enter', () => {
      tweenUp.play();
    })
    .addTo(controllerMove);
  });

  moveRight.forEach(rightItem => {
    const tweenRight = new TimelineLite({paused: true})
    tweenRight.fromTo(rightItem, 0.7, {x: -70, opacity: 0}, {x: 0, opacity: 1, ease: Power1.easeOut });

    new ScrollMagic.Scene({
      triggerElement: rightItem,
      offset: -(window.innerHeight * 0.4)
    })
    .on('enter', () => {
      tweenRight.play();
    })
    .addTo(controllerMove);
  });
}

export default scroll;
