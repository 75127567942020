import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import WhatWeDo from './components/WhatWeDo';
import OurWork from './components/OurWork';
import Project from './components/Project';
import Contact from './components/Contact';
import Team from './components/Team';
import NotFound from './components/NotFound';

const advancedMatching = {
  em: ''
  // em: 'some@email.com'
}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
  // debug: false, // enable logs
};
ReactPixel.init(303159614893810, advancedMatching, options);
ReactPixel.pageView(); // For tracking page view

const App = props => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <div className="route-container">
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/index2.html' exact component={Home} />
            <Route path='/what-we-do' component={WhatWeDo} />
            <Route path='/our-work' exact component={OurWork} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/our-work/:slug' component={Project}  />
            <Route path='/team' component={Team} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App;