import ScrollMagic from 'scrollmagic';

const project = () => {
  const parallax = document.querySelectorAll('.parallax');
  const controllerParallax = new ScrollMagic.Controller();

  parallax.forEach(parallaxBlock => {
    let p1, p2, p3, p4, topDistance, scrollTop, offset;

    p1 = parallaxBlock.querySelector('.p1');
    p2 = parallaxBlock.querySelector('.p2');
    p3 = parallaxBlock.querySelector('.p3');
    p4 = parallaxBlock.querySelector('.p4');

    if (window.innerWidth >= 768) {
    } else {
       p1.parentNode.style.height = 'auto';
    }

    const pa = () => {
      scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
      topDistance = window.pageYOffset + parallaxBlock.getBoundingClientRect().top;
      offset = scrollTop - topDistance;

      p1.style.transform = 'translateY('+(-offset/10)+'px)';
      p2.style.transform = 'translateY('+(-offset/5)+'px)';
      if(p3 !== null) p3.style.transform = 'translateY('+(-offset/4)+'px)';
      if(p4 !== null) p4.style.transform = 'translateY('+(-offset/3)+'px)';
    }

    const parallaxUp = () => {
      if (window.innerWidth >= 768) {
        window.addEventListener('scroll', pa);
      }

      if (window.innerWidth < 768) {
        p1.parentNode.style.height = 'auto';
        window.removeEventListener('scroll', pa);
        p1.style.transform = 'translateY(0px)';
        p2.style.transform = 'translateY(0px)';
        if(p3 !== null) p3.style.transform = 'translateY(0px)';
        if(p4 !== null) p4.style.transform = 'translateY(0px)';
      }
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        p1.parentNode.style.height = 'auto';
        window.removeEventListener('scroll', pa);
        p1.style.transform = 'translateY(0)';
        p2.style.transform = 'translateY(0)';
        if(p3 !== null) p3.style.transform = 'translateY(0)';
        if(p4 !== null) p4.style.transform = 'translateY(0)';
      } else {
        parallaxUp();
      }
    });

    new ScrollMagic.Scene({
      triggerElement: parallaxBlock,
      offset: -(window.innerHeight * 0.9)
    })
    .on('enter', () => {
      parallaxUp();
    })
    .addTo(controllerParallax);
  });
}

export default project;