import React, { useEffect } from 'react';
import scroll from '../js/scroll';
import Member from './Member';
import fadein from '../js/fadein';
import members from '../data/members';
import advisors from '../data/advisors';
import Start from './Start';

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    scroll();
    fadein();
  }, [])

  return (
    <div className="team section light fade-in">
      <div className="team__inner wrapper">
      <Start />
        <div className="team__header">
          <h1 className="team__title">
            <span>Our</span>
            <span>Team</span>
          </h1>
          <div className="team__intro">
            <p>MediaWorx is a collective made up of data-driven creatives that adore technology. Our greatest strength is the pride we take in everything we do. We simply don’t have it in us to produce something we don’t feel is a home run. Experience, knowledge and talent swirl through the corridors of our digital agency landing on the desks of our clients with the unwavering intention of helping said clients shred their goals. Nothing less will do.</p>
          </div>
        </div>
        <div className="members">
         { members.map( member => <Member picture={member.picture} name={member.name} role={member.role} />) }
        </div>

        <h2 className="team__subtitle">Advisors</h2>

        <div className="members">
         { advisors.map( member => <Member picture={member.picture} name={member.name} role={member.role} />) }
        </div>
      </div>
    </div>
  )
}

export default Team