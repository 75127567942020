import React from 'react';
import { ReactComponent as Mouse } from '../svg/mouse.svg';

const Hero = () => {
  return (
    <div className="hero section dark">
      <div className="hero__content">
        <h1 className="hero__title">
          <span>Branding.</span>
          <span>Digital.</span>
          <span>Design.</span>
        </h1>
        <p className="hero__intro">
          You need a partner that gets you.
          A partner that shares your vision, a partner that locks elbows with you to create something better than status quo.
          MediaWorx is made up of a collective of creatives, marketers,
          strategists and engineers that feel your success is equal to their own.
          Tap into the power of a true partner to guide you on your quest for greatness in your industry.
        </p>
      </div>
      <div className="hero__mouse">
        <Mouse/>
      </div>
    </div>
  )
}

export default Hero;