import {TimelineLite, Expo} from 'gsap';
import scroll from '../js/scroll';

const header = () => {
  const nav = document.querySelector('.nav');

  const logo = document.querySelector('.header__logo');
  const logoOverlay = document.querySelector('.header__logo-overlay');
  const pull = document.querySelector('.header__pull');
  const pullOverlay = document.querySelector('.header__pull-overlay');
  const hello = document.querySelector('.header__hello');
  const helloOverlay = document.querySelector('.header__hello-overlay');
  const navHero = document.querySelector('.nav-hero');
  const navItem = document.querySelectorAll('.nav__item');

  logoOverlay.classList.remove('visible');

  const openMenu = () => {
    const showOverlay = () => {
      logo.classList.add('hidden');
      logoOverlay.classList.add('visible');
      pull.classList.add('hidden');
      pullOverlay.classList.add('visible');
      hello.classList.add('hidden');
      helloOverlay.classList.add('visible');
    }

    setTimeout(showOverlay, 600);

    nav.classList.add('open');
    document.body.classList.add('isMenuOpened');

    const tweenNavOpen = new TimelineLite({paused: true});
    tweenNavOpen

    .fromTo(nav, 0.7, {left: '-100%'}, {left: 0, ease: Expo.easeIn})
    .staggerFromTo(navItem, 0.3, {y: '50px', opacity: 0}, {y: 0, opacity: 1, ease: Expo.easeOut}, 0.05, "+= 0.2")
    .to(pullOverlay, 0.1, {css:{className:'+=visible'}})
    .to(pullOverlay, 0.1, {css:{className:'+=closed'}}, "-=0.8")

    tweenNavOpen.restart();

  }

  const closeMenu = () => {
    const hideOverlay = () => {
      logo.classList.remove('hidden');
      logoOverlay.classList.remove('visible');
      pull.classList.remove('hidden');
      pullOverlay.classList.remove('visible');
      hello.classList.remove('hidden');
      helloOverlay.classList.remove('visible');
      scroll();
    }

    pullOverlay.classList.add('closed');

    setTimeout(hideOverlay, 600);
    nav.classList.remove('open');
    document.body.classList.remove('isMenuOpened');

    const tweenNavClose = new TimelineLite({paused: true});
    tweenNavClose

    .to(navItem, 0.3, {y: '-200px', opacity: 0, ease: Expo.easeOut})
    .fromTo(nav, 0.8, {left: '0'}, {left: '100%', ease: Expo.easeIn}, "-=1.4")
    .to(pullOverlay, 0.1, {css:{className:'-=closed'}}, "-=1.4")
    .to(pullOverlay, 0.1, {css:{className:'-=visible'}})
    tweenNavClose.restart();

  }

  pull.addEventListener('click', () => {
    openMenu();
  });

  pullOverlay.addEventListener('click', () => {
    closeMenu();
  });

  navHero.addEventListener('click', () => {
    closeMenu();
  });

  logoOverlay.addEventListener('click', () => {
    if (document.body.classList.contains('isMenuOpened')) {
      closeMenu();
    }
  });

  navItem.forEach(item => {
    item.addEventListener('click', () => {
      closeMenu();

    })
  })
}

export default header;
