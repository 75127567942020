import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import scroll from '../js/scroll';
import { ReactComponent as Arrow } from '../svg/arrow.svg';
import Start from './Start';
import fadein from '../js/fadein';

const WhatWeDo = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    scroll();
    fadein();
  }, [])

  return (
    <div className="what-we-do fade-in">
      <div className="wrapper section light">
       <Start />
       <div className="what-we-do__header">
            <span className="what-we-do__header-span">We Are</span>
            <span className="what-we-do__header-span what-we-do__header-moving">Branding / Design / Digital</span>
            <span className="what-we-do__header-span">And Way</span>
            <span className="what-we-do__header-span">More.</span>
          </div>
        <div className="what-we-do__top">
          <div className="what-we-do__top-intro ">
           <p>
             MediaWorx takes a holistic approach to your success.
             A project in a box or a one-and-done campaign will not get you where you need to be for the long-term.
             We work tirelessly to develop the strategy that fits your business,
             wow you with creative that may take your breath away and execute seamlessly + measurably.
             That’s what we do.
           </p>
          </div>
          <div className="what-we-do__top-tag">
            <h2>if you want to take your brand
            to another level, that is who
            you should call
            </h2>
            <h3>For those who are still calling!</h3>
          </div>
        </div>

        <div className="what-we-do__grid">
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">ADVERTISING </h2>
            <p className="what-we-do__block-text">
              Scratch that...we don’t believe in advertising.
              We believe in authenticity, connections and experiences.
              Moments not messages. That’s how we craft your story.
              That’s how you succeed.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">BRANDING</h2>
            <p className="what-we-do__block-text">Who are you? Can your customers point you out in a crowd?
              We translate your core, your ethos, your DNA into a brand.
              At every touchpoint from your logo to your brand voice to your creative design,
              the spirit of your business will shine through.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">STRATEGY</h2>
            <p className="what-we-do__block-text">
              Research, analytics and data are the backbone of our work and your success.
              We have the expertise and tools to craft a metric-driven strategy that is supported by science and historical performance.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">DESIGN</h2>
            <p className="what-we-do__block-text">
              Every color, every line and every space filled or left untouched is under consideration.
              Our flair for the creative is only trumped by our aptitude for
              translating your vision into the details of a crisp, clean design.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">WEB</h2>
            <p className="what-we-do__block-text">
              The perfect combination of front-end and back-end professionals at the top of their games change the face of your online presence.
              We are experts in (and slightly obsessed with) UI and UX,
              which often makes the difference between a sale and an abandoned shopping cart.
              We understand the subtle nuances that drive traffic to your site and turn visitors from clicks to conversions.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">SOCIAL</h2>
            <p className="what-we-do__block-text">
              Social media is the channel by which you can prospect, engage with current customers and reinforce your personality.
              Our social media gurus will craft an integrated social media ecosystem that is sustained, branded and measurable.
            </p>
          </div>
          <div className="what-we-do__block move-up">
            <h2 className="what-we-do__block-title">eTools</h2>
            <p className="what-we-do__block-text">
              Based on the key areas of opportunity and needs, we build customized digital tools to help improve
              your internal processes and make you more efficient.
              Not a one-size-fits-all approach, we engineer a tool from the ground up that fits your business uniquely.
              You’ll enjoy streamlined processes, teams that are in-the-know, well tracked performance + so much more.
            </p>
          </div>
        </div>
        <div className="what-we-do__cta square move-up">
          <Link to="/our-work">
            <span className="square__span-text">See the agency projects</span>
            <span className="square__span-image"><Arrow/></span>
          </Link>
        </div>
      </div>
      <div className="what-we-do__footer section dark" style={{backgroundImage: "url(../img/pages/what-we-do.jpg)"}}>
      </div>
    </div>
  )
}

export default WhatWeDo