const Members = [
  {picture: '../img/team/ernesto-fuenmayor.jpg', name: 'Ernesto Fuenmayor', role: 'Director'},
  {picture: '../img/team/william-stalhuth.jpg', name: 'William Stalhuth', role: 'Senior Art Director'},
  {picture: '../img/team/jesus-blanco.jpg', name: 'Jesus Blanco', role: 'Art Director'},
  {picture: '../img/team/genaro-desia.jpg', name: 'Genaro De Sia', role: 'Digital & Dev Director | Full-Stack Developer'},
  {picture: '../img/team/claudia-fuenmayor.jpg', name: 'Claudia Fuenmayor', role: 'Traffic & QA Manager'},
  // {picture: '../img/team/manuel-acosta.jpg', name: 'Manuel Acosta', role: 'Senior Graphic Designer'},
  {picture: '../img/team/jorge-parra.jpg', name: 'Jorge Parra', role: 'UX/UI Senior Designer | Front-end Developer'},
  {picture: '../img/team/abraham-cruz.jpg', name: 'Abraham Cruz', role: 'Senior Graphic Designer'},
  {picture: '../img/team/valentina-felce.jpg', name: 'Valentina Felce', role: '3D designer'},
  {picture: '../img/team/eduardo-plaza.jpg', name: 'Eduardo Plaza', role: 'Lead Front-end Developer'},
  {picture: '../img/team/jesus-gonzalez.jpg', name: 'Jesus Gonzalez', role: 'Video and multimedia'},
  {picture: '../img/team/gilberto-rivas.jpg', name: 'Gilberto Rivas', role: 'Senior Designer (Print)'},
  {picture: '../img/team/mariana-graterol.jpg', name: 'Mariana Graterol', role: 'Copywriter'},
  {picture: '../img/team/pablo-garcia.jpg', name: 'Pablo Jesus Garcia', role: 'Web Developer'},
]

export default Members
