import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import scroll from '../js/scroll';
import contact from '../js/contact';
import fadein from '../js/fadein';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as Logo } from '../svg/logo-circle.svg';

ReactPixel.track('Contact');

const AnyReactComponent = () => {
  return (
    <div className="contact__logo-map">
      <Logo />
      <h3>MediaWorx</h3>
    </div>
  )
}

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fadein();
    scroll();
    contact();
  }, [])

  const center = { lat: 26.1394, lng: -80.4244 }
  const zoom = 17;

  const createMapOptions = (maps) => {
    return {
      scrollwheel: false,
      styles: [{ stylers: [{ 'saturation': -80 }, { 'lightness': 1 }] }]
    }
  }

  return (
    <div className="contact fade-in">
      <div className="wrapper section light">
        <div className="contact__header">
          <h1 className="contact__title">Contact</h1>
        </div>
        <div className="contact__top">
          <div className="contact__top-tag">
            <h2>Let's connect.</h2>
            <p>Whether you’re ready to jump in or just want to learn more about MediaWorx, we’d love to connect. Drop us an email, call us up and connect online - we’re excited to learn more about your business and your goals.</p>
          </div>
        </div>
        <div className="contact__main">
          <div className="contact__mask"></div>
          <div className="contact__output">Hola</div>
          <div className="contact__form">
            <form className="form" noValidate>
              <input
                className='input-name'
                type="text"
                placeholder='Name'
              />
              <input
                className="input-email"
                placeholder='Email'
              />
              <input
                className="input-message"
                placeholder='Message'
              />
              <button
                onClick={
                  () => {
                    // ReactPixel.Lead();
                    ReactPixel.track('Lead');
                  }
                }
                className="submit button-arrow-black"
              >
                Send
                <img src="../img/svg/arrow-white.svg" alt="arrow" />
              </button>
            </form>
          </div>
          <div className="contact__info quick">
            <p>2700 Glades Circle. Suite 130.<br />Weston, FL 33327</p>
            <p className="quick__link quick__link-big">t: <a href="tel:+19543899960" >+1 954 389 9960</a></p>
            <p className="quick__link quick__link-big">m: <a href="mailto:hello@mwxdigital.com?subject=Hello">hello@mwxdigital.com</a></p>
          </div>
        </div>
        <div className="contact__google">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDU-EGe1nfxUaE0HB31HWV9eUy54lvchgo' }}
            defaultCenter={center}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            options={createMapOptions}
          >
            <AnyReactComponent
              lat={26.1394932}
              lng={-80.4244958}
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  )

}

export default Contact