import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../svg/arrow.svg';
import * as THREE from 'three';
import {TweenMax, Linear} from 'gsap';

const Plan = () => {
 useEffect(() => {
    const allTitles = document.querySelectorAll('.plan-title');
    const mount = document.querySelector('.loop');
    let width;
    let height;
    let frameId;
    let camera;
    let i = 1;
    const imgs = Array.from(mount.querySelectorAll('img'));
    const dispImage = mount.dataset.displacement;
    const image1 = imgs[0].getAttribute('src');
    const image2 = imgs[1].getAttribute('src');
    const image3 = imgs[2].getAttribute('src');
    const intensity = -0.65;
    const speedIn = 0.5;
    const easing = Linear.easeOut;

    const vertex = `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `;

    const fragment = `
      varying vec2 vUv;

      uniform sampler2D texture;
      uniform sampler2D texture2;
      uniform sampler2D disp;

      uniform float dispFactor;
      uniform float effectFactor;

      void main() {
          vec2 uv = vUv;
          vec4 disp = texture2D(disp, uv);

          vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*effectFactor), uv.y);
          vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*effectFactor), uv.y);

          vec4 _texture = texture2D(texture, distortedPosition);
          vec4 _texture2 = texture2D(texture2, distortedPosition2);

          vec4 finalTexture = mix(_texture, _texture2, dispFactor);

          gl_FragColor = finalTexture;
      }
    `;

    const scene = new THREE.Scene()

    if (window.innerWidth > 768) {
      camera = new THREE.OrthographicCamera(
        mount.offsetWidth / -2.4,
        mount.offsetWidth / 2.4,
        mount.offsetHeight / 2,
        mount.offsetHeight / -2,
        1,
        1000
      );
    } else {
      camera = new THREE.OrthographicCamera(
        mount.offsetWidth / -2.3,
        mount.offsetWidth / 2.3,
        mount.offsetHeight / 2,
        mount.offsetHeight / -2,
        1,
        1000
      );
    }



    const renderer = new THREE.WebGLRenderer({ antialias: true })
    const loader = new THREE.TextureLoader();

    const texture1 = loader.load(image1);
    const texture2 = loader.load(image2);
    const texture3 = loader.load(image3);
    const disp = loader.load(dispImage);
    disp.wrapS = disp.wrapT = THREE.RepeatWrapping;

    //Filters
    texture1.magFilter = texture2.magFilter = texture3.magFilter = THREE.LinearFilter;
    texture1.minFilter = texture2.minFilter = texture3.minFilter = THREE.LinearFilter;
    texture1.anisotropy = renderer.getMaxAnisotropy();
    texture2.anisotropy = renderer.getMaxAnisotropy();
    texture3.anisotropy = renderer.getMaxAnisotropy();

     let geometry = new THREE.PlaneBufferGeometry(
        mount.offsetWidth,
        mount.offsetHeight,
        1
      );

    let material1 = new THREE.ShaderMaterial({
      uniforms: {
        effectFactor: { type: "f", value: intensity },
        dispFactor: { type: "f", value: 0.0 },
        texture: { type: "t", value: texture1 },
        texture2: { type: "t", value: texture2 },
        disp: { type: "t", value: disp }
      },

      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      opacity: 1.0
    });

    let material2 = new THREE.ShaderMaterial({
      uniforms: {
        effectFactor: { type: "f", value: intensity },
        dispFactor: { type: "f", value: 0.0 },
        texture: { type: "t", value: texture2 },
        texture2: { type: "t", value: texture3 },
        disp: { type: "t", value: disp }
      },

      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      opacity: 1.0
    });

    let material3 = new THREE.ShaderMaterial({
      uniforms: {
        effectFactor: { type: "f", value: intensity },
        dispFactor: { type: "f", value: 0.0 },
        texture: { type: "t", value: texture3 },
        texture2: { type: "t", value: texture1 },
        disp: { type: "t", value: disp }
      },

      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      opacity: 1.0
    });

    let object1 = new THREE.Mesh(geometry, material1);
    let object2 = new THREE.Mesh(geometry, material2);
    let object3 = new THREE.Mesh(geometry, material3);

    camera.position.z = 1;
    scene.add(object1)

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0xffffff, 0.0);
    renderer.setSize(mount.offsetWidth, mount.offsetHeight);

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    const handleResize = () => {
      width = mount.clientWidth
      height = mount.clientHeight
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }

    const animate = () => {
      renderScene()
      frameId = window.requestAnimationFrame(animate)
    }

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(animate)
      }
    }

    const addEvents = i => {
      if (i === 0) {
        scene.remove(object2)
        scene.remove(object3)
        scene.add(object1)
        TweenMax.fromTo(material1.uniforms.dispFactor, speedIn, {value: 1}, {value: 0, ease: easing})
      } else if ( i === 1) {
        scene.remove(object1)
        scene.remove(object3)
        scene.add(object2);
        TweenMax.fromTo(material2.uniforms.dispFactor, speedIn, {value: 1}, {value: 0, ease: easing})
      } else if ( i === 2) {
        scene.remove(object1)
        scene.remove(object2)
        scene.add(object3);
        TweenMax.fromTo(material3.uniforms.dispFactor, speedIn, {value: 1}, {value: 0, ease: easing})
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId)
      frameId = null
    }

    mount.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    start()

    const texts = [
      'Everything starts with a solid plan. By understanding your objective and putting data and analytics behind the strategy, we’ll create the roadmap to take things to the next level.',
      'Be prepared to be overwhelmed. During the execution phase, our mission is to submit work to our clients that encompasses their every wish, even those that were left unsaid.',
      'No success should go unmeasured. Without metrics and KPIs, valuable insights are lost. We use our marketing expertise (and cutting-edge technology) to implement a vehicle to measure, analyze and optimize every effort.'
    ];
    const tag = document.querySelector('.plan__features-tag');

    const addHightLight = () => {
      if (i === 3) i = 0;
      addEvents(i);
      tag.innerHTML = texts[i];
      let selected = document.querySelector('.plan-title-'+i);
      allTitles.forEach( title => title.classList.remove('selected'))
      selected.classList.add('selected');
      i++;
    }
    let refreshIntervalId = setInterval(addHightLight, 7000);

    return () => {
      stop()
      clearInterval(refreshIntervalId)
      window.removeEventListener('resize', handleResize)
      mount.removeChild(renderer.domElement)
      scene.remove(object1)
      scene.remove(object2)
      scene.remove(object3)
      geometry.dispose()
      material1.dispose()
      material2.dispose()
      material3.dispose()
    }

  }, [])

  return (
    <div className="plan section light">
      <div className="plan__top wrapper">
        <h2 className="plan__title move-up">What We Do</h2>
        <p className="plan__intro move-up">
          Create brand from ground zero-including name + logo + image.
          Refresh legacy brand that lost its way.
          Take breath away with epic creative.
          Improve business operations through customized digital solutions.
          Launch integrated strategy for groundbreaking startups. More things like this.
          +Make sure we can measure, of course.
        </p>
      </div>
      <div className="plan__features">
        <div className="plan__features-images">
          <div className="plan__features-image loop" data-displacement="img/displacement/noise.jpg">
            <img className="plan__features-img-front" src="../img/pages/plan0.jpg" alt="Plan 0" />
            <img className="plan__features-img-front" src="../img/pages/plan1.jpg" alt="Plan 1" />
            <img className="plan__features-img-front" src="../img/pages/plan2.jpg" alt="Plan 2" />
          </div>
        </div>
        <div className="plan__features-steps">
          <ul className="plan__features-list move-up">
            <li><span>01</span><h3 className="plan-title plan-title-0 selected">Plan</h3></li>
            <li><span>02</span><h3 className="plan-title plan-title-1">Create</h3></li>
            <li><span>03</span><h3 className="plan-title plan-title-2">Measure</h3></li>
          </ul>
          <p className="plan__features-tag move-up">
            Everything starts with a solid plan.
            By understanding your objective and putting data and analytics behind the strategy,
            we’ll create the roadmap to take things to the next level.
          </p>
          <div className="plan__features-cta square move-up">
            <Link to="/our-work">
              <span className="square__span-text">See the agency projects</span>
              <span className="square__span-image"><Arrow/></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plan;