import React, { useState } from 'react';
import Slider from "react-slick";

const Testimonials = () => {
  const [count, setCount] = useState('01');

  const ArrowPrev = props => {
    const { onClick } = props;
    return (
      <button className="testimonials__arrow testimonials__arrow--prev" onClick={onClick}><img src="../img/svg/prev.svg" alt="arrow"/></button>
    );
  }

  const ArrowNext = props => {
    const { onClick } = props;
    return (
      <button className="testimonials__arrow testimonials__arrow--next" onClick={onClick}><img  src="../img/svg/next.svg" alt="arrow"/></button>
    );
  }

  var settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    afterChange: a => {
      if (a < 10) {
        a = a + 1;
        a = '0'+a;
      } else {
        a = +a;
      }
      setCount(a)
    }
  };

  return (
    <div className="testimonials">
      <div className="testimonials__hero section dark" style={{backgroundImage: "url(../img/pages/testimonials.jpg)"}}>
      </div>
      <div className="testimonials__content wrapper section light">
        <div className="testimonials__slides move-up">
          <Slider {...settings}>
            <div className="testimonial">
              <p className="testimonial__text">"MediaWorx helped us create the identity of our company. We knew where we wanted to go with our startup but didn’t know how to get there. The team at MediaWorx came back with a name, logo, stationery and a website layout that my vision only better."</p>
              <h2 className="testimonial__name">Robert F.</h2>
              <h3 className="testimonial__position">Startup Founder</h3>
            </div>
            <div className="testimonial">
              <p className="testimonial__text">"My company has worked with agencies in the past, but none were as focused on measurables as MediaWorx. Part of my role as Marketing Director is to show ROI for every dollar I spend, MediaWorx made that easy!"</p>
              <h2 className="testimonial__name">Jenna B.</h2>
              <h3 className="testimonial__position">Director of Marketing</h3>
            </div>
            <div className="testimonial">
              <p className="testimonial__text">"I needed a partner that could not only guide me as to what my digital marketing strategy should look like but also execute. Working with MediaWorx was like adding a group of A-players to my team. They brought talent, insight and measurable results."</p>
              <h2 className="testimonial__name">Keith P.</h2>
              <p className="testimonial__position">CEO</p>
            </div>
          </Slider>
          <div className="testimonials__counter"><span>{count}</span><span> / 03</span></div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials;