import React, { useEffect, useState } from 'react';
import scroll from '../js/scroll';
import Arrows from './Arrows';
import ArrowsTop from './ArrowsTop';
import { Link } from 'react-router-dom';
import Projects from '../data/projects';
import { ReactComponent as Arrow } from '../svg/arrow.svg';
import Start from './Start';
import fadein from '../js/fadein';
import project from '../js/project';

const Project = () => {
  const [client, setClient] = useState('');
  const [type, setType] = useState('');
  const [spacing, setSpacing] = useState('');
  const [visit, setVisit] = useState('');
  const [top, setTop] = useState('');
  const [intro, setIntro] = useState('');
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState('');
  const [cover, setCover] = useState('');
  const [banner, setBanner] = useState('');
  const [rendered, setRendered] = useState('0');
  const [prev, setPrev] = useState('');
  const [next, setNext] = useState('');
  const [logoVisibility, setLogoVisibility] = useState('');
  const [logoPos, setLogoPos] = useState('');

  useEffect(() => {
    loadData();
    window.scrollTo(0, 0);
    if (rendered === '1') {
      scroll();
      project();
      fadein();

    }

  }, [rendered])

  const update = () =>  {
    setRendered('0');
    setLogoVisibility('');
  }

  const loadData = () => {

    let url = window.location.href;
    let lastPart = url.split("/").pop();
    let currentSlug = lastPart;
    let currentProject = Projects.find(p => p.slug === currentSlug);
    let currentIndex = Projects.indexOf(currentProject);
    let nextProject;
    let prevProject;

    currentIndex === 0 ? prevProject = Projects[Projects.length - 1].slug : prevProject = Projects[currentIndex - 1].slug;
    currentIndex === (Projects.length - 1 ) ? nextProject = Projects[0].slug : nextProject = Projects[currentIndex + 1].slug;

    setPrev(prevProject);
    setNext(nextProject);
    setClient(currentProject.client);
    setType(currentProject.type);
    setSpacing(currentProject.spacing);
    setVisit(currentProject.visit);
    setTop(currentProject.top);
    setIntro(currentProject.intro);
    setImages(currentProject.images);
    setCover(currentProject.cover);
    setBanner(currentProject.banner);
    setLogo(currentProject.logo);
    setLogoPos(currentProject.logoPos);
    setRendered('1');
    setLogoVisibility('visible');

  }

  return (
    <div className="project fade-in">
     <Start />
      <ArrowsTop update={() => update()} prev={prev} next={next} color={top}/>
      <div className={`project__top section ${top} logo-${logoPos || 'center'}`} style={{backgroundImage: `url(${cover})`}} >
        <div className="project__top-content">
          <img className={`project__top-logo ${logoVisibility}`} src={logo} alt=""/>
        </div>

      </div>
      <div className={top === 'dark' ? 'project__bar' : 'project__bar section dark'}>
        <div className="project__bar-inner container">
          <div className="project__bar-item">
            <h4>
              <Link className="project__bar-itemAll" to="/our-work"><Arrow/>All PROJECTS</Link>
            </h4>
          </div>
          <div className="project__bar-item">
            <h2>Client</h2>
            <h3>{client}</h3>
          </div>

          <div className="project__bar-item">
            <h2>Project</h2>
            <h3>{type}</h3>
          </div>
          {
            visit !== '' ?
              (
                <div className="project__bar-item">
                  <h2>Visit</h2>
                  <h3><a target="_blank" rel="noopener noreferrer" href={visit}>Website</a></h3>
                </div>
              ) : (
                <div className="project__bar-item"></div>
              )
          }

        </div>
      </div>

      <div className={spacing === 'space' ? 'project__content section light space' : 'project__content section light no-space'}>
        <div className="project__content-inner container">
          { intro ? <p className="project__content-intro">{intro}</p> : null }
          {images.map(image => {
            return (
              <div key={Math.random(1,100)} className="project__content-image ">

                {
                  image.img ?
                    (
                      <img className="move-up" src={image.img} alt='project'/>
                    ) : (


                      <div className="parallax" style={{height: image.parallax.height, maxHeight: image.parallax.maxHeight}}>
                        <div
                          className="p1"
                          style={{
                            top: image.parallax.item1.top,
                            left: image.parallax.item1.left,
                            right: image.parallax.item1.right,
                            width: image.parallax.item1.width
                          }}
                        >
                          <img className="img1" src={image.parallax.item1.img} alt='project'/>
                        </div>
                        <div
                          className="p2"
                          style={{
                            top: image.parallax.item2.top,
                            left: image.parallax.item2.left,
                            right: image.parallax.item2.right,
                            width: image.parallax.item2.width
                          }}
                        >
                          <img className="img2" src={image.parallax.item2.img} alt='project'/>
                        </div>
                        {image.parallax.item3 ? <div className="p3"  style={{
                          top: image.parallax.item3.top,
                          left: image.parallax.item3.left,
                          right: image.parallax.item3.right,
                          width: image.parallax.item3.width
                        }}><img className="img3" src={image.parallax.item3.img} alt='project'/></div> : null }
                        {image.parallax.item4 ? <div className="p4"  style={{
                          top: image.parallax.item4.top,
                          left: image.parallax.item4.left,
                          right: image.parallax.item4.right,
                          width: image.parallax.item4.width
                        }}><img className="img4" src={image.parallax.item4.img} alt='project'/></div> : null }
                      </div>
                    )
                }

                { image.tag ?  <p>{image.tag}</p> : null }
              </div>
            )
          })}
        </div>
      </div>
      { banner ?  <div className="project__banner section dark" style={{backgroundImage: `url(${banner})`}} ></div> : null }

      { banner ?  <Arrows update={() => update()} prev={prev} next={next}/> :  <Arrows dark='section dark' update={() => update()} prev={prev} next={next}/>}
    </div>
  )

}

export default Project