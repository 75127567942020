import React from 'react'
import Ticker from 'react-ticker'

const Clients = () => {
  return (
    <div className="clients">
      <Ticker offset={0} speed={10}>
        {({ index }) => (
          <div className="clients__logos">
            <img  src="../img/clients/marriott.svg" alt="Marriott"/>
            <img className="big" src="../img/clients/comcast.svg" alt="Comcast"/>
            <img src="../img/clients/amerant.svg" alt="Amerant"/>
            <img className="big" src="../img/clients/mercantil.svg" alt="Mercantil"/>
            <img src="../img/clients/sony.svg" alt="Sony"/>
            <img className="big" src="../img/clients/claro.svg" alt="Claro"/>
            <img src="../img/clients/blackberry.svg" alt="Blackberry"/>
            <img src="../img/clients/idt.svg" alt="IDT"/>
            <img src="../img/clients/oceanbank.svg" alt="Ocean Bank"/>
            <img className="big" src="../img/clients/alpina.svg" alt="Alpina"/>
            <img src="../img/clients/boatsetter.svg" alt="Boatsetter"/>
            <img src="../img/clients/ymedia.svg" alt="Ymedia"/>
            <img className="big" src="../img/clients/locatel.svg" alt="Locatel"/>
            <img src="../img/clients/momo.svg" alt="Momo"/>
          </div>
        )}
      </Ticker>
    </div >
  )
}

export default Clients;
