const Projects = [

  //Boatsetter etools
  {
    client: 'Boatsetter',
    type: 'Emails',
    tag: 'emails',
    slug: 'boatsetter-emails',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/boatsetter/boatsetter.svg",
    thumb: "../img/projects/boatsetter/emails/bs-emails.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/boatsetter/emails/H1_BS_ETOOLS.jpg",
    top: "dark",
    intro: 'Boatsetter is a boat rental company that allows individuals to connect with boat owners and captains to embark on your water experience. Following their email marketing strategy, we designed and programmed different series of email blasts that are both visually appealing and display correctly across platforms and email clients.',
    images: [
      {
        img: '../img/projects/boatsetter/emails/H2_BS_ETOOLS.png',
      },
      {
        img: '../img/projects/boatsetter/emails/H3_BS_ETOOLS.png',
      },
      {
        img: '../img/projects/boatsetter/emails/H4_BS_ETOOLS.jpg',
      },
      {
        img: '../img/projects/boatsetter/emails/H5_BS_ETOOLS.jpg',
      },
      {
        img: '../img/projects/boatsetter/emails/H6_BS_ETOOLS.jpg',
      },
      {
        img: '../img/projects/boatsetter/emails/H7_BS_ETOOLS.jpg',
      },
      {
        img: '../img/projects/boatsetter/emails/H8_BS_ETOOLS.jpg',
      },
      {
        img: '../img/projects/boatsetter/emails/H9_BS_ETOOLS.png',
      },

    ]
  },

  //Auer print
  {
    client: 'Auer',
    type: 'Print',
    tag: 'print',
    slug: 'auer-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/auer/auer.svg",
    thumb: "../img/projects/auer/print/auer-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/auer/print/H1_AUER.jpg",
    top: "dark",
    intro: 'Auer Automotive is an Award-Winning Supplier and developer of high-quality products that enhance Automotive Safety and Style. We designed a variety of pieces for print such as product catalog, postcards, and product displays.',
    images: [
      {
        img: '../img/projects/auer/print/H2_AUER.jpg',
      },
      {
        img: '../img/projects/auer/print/H3_AUER.png',
      },
      {
        img: '../img/projects/auer/print/H4_AUER.png',
      },
      {
        img: '../img/projects/auer/print/H5_AUER.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1550px',
          item1: {
            img: '../img/projects/auer/print/H6-1_AUER.png',
            top: 0,
            left: '4rem',
            right: 'auto',
            width: '40%'
          },
          item2: {
            img: '../img/projects/auer/print/H6-2_AUER.png',
            top: '20%',
            left: 'auto',
            right: '4rem',
            width: '40%'
          }
        }
      }

    ],
    banner: '../img/projects/auer/print/H7_AUER.jpg'
  },

  //Boatsetter print
  {
    client: 'Boatsetter',
    type: 'Print',
    tag: 'print',
    slug: 'boatsetter-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/boatsetter/boatsetter.svg",
    thumb: "../img/projects/boatsetter/print/bs-print.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/boatsetter/print/H1_BS_PRINT.jpg",
    top: "dark",
    intro: 'Boatsetter is a boat rental company that allows individuals to connect with boat owners and captains to embark on your water experience. Our team conceptualized and designed a variety of printed pieces for direct marketing, events, corporate, sales, transactional, tradeshows, and many more.',
    images: [
      {
        img: '../img/projects/boatsetter/print/H2_BS_PRINT.jpg',
      },
      {
        img: '../img/projects/boatsetter/print/H3_BS_PRINT.jpg',
      },
       {
        img: '../img/projects/boatsetter/print/H4_BS_PRINT.png',
      },
       {
        img: '../img/projects/boatsetter/print/H5_BS_PRINT.jpg',
      },
       {
        img: '../img/projects/boatsetter/print/H6_BS_PRINT.jpg',
      },
      {
        img: '../img/projects/boatsetter/print/H7_BS_PRINT.jpg',
      },
      {
        img: '../img/projects/boatsetter/print/H8_BS_PRINT.jpg',
      }
    ]
  },

  //Apostol print
  {
    client: 'Apostol Cerveza Premium',
    type: 'Print',
    tag: 'print',
    slug: 'apostol-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/apostol/apostol.svg",
    thumb: "../img/projects/apostol/print/apostol-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/apostol/print/H1_APOSTOL.jpg",
    top: "dark",
    intro: 'Apostol Cerveza Premium is a Colombian beer company dedicated to providing high-quality products that accommodate people’s different tastes. In collaboration with IME, we created the concept and signs of kiosks, pushcarts, mobile bars, kegs, as well as the general mood and look and feel for Apostol Craft Beer.',
    images: [
      {
        img: '../img/projects/apostol/print/H2_APOSTOL.jpg',
      },
      {
        img: '../img/projects/apostol/print/H3_APOSTOL.jpg',
      },
      {
        img: '../img/projects/apostol/print/H4_APOSTOL.jpg',
      },
      {
        img: '../img/projects/apostol/print/H5_APOSTOL.jpg',
      },

    ],
    banner: '../img/projects/apostol/print/H6_APOSTOL.jpg'
  },

  //Bebek packaging
  {
    client: 'Bebek',
    type: 'Packaging',
    tag: 'print',
    slug: 'bebek-print2',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/bebek/bebek.svg",
    thumb: "../img/projects/bebek/print2/bebek-print2.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/bebek/print2/H1_BEBEK_PK.jpg",
    top: "light",
    intro: 'Bebēk focuses on creating simple, effective, and beautiful feeding products that combine cutting edge technology and superior designs. We carefully designed the rebranding of Bebēk and developed the complete line of packaging for all of their products, elevating the perception price over 80 percent compared to their older brand.',
    images: [
      {
        img: '../img/projects/bebek/print2/H2_BEBEK_PK.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1850px',
          item1: {
            img: '../img/projects/bebek/print2/H3-1_BEBEK_PK.png',
            top: 0,
            left: '5%',
            right: 'auto',
            width: '35%'
          },
          item2: {
            img: '../img/projects/bebek/print2/H3-2_BEBEK_PK.png',
            top: 0,
            left: 'auto',
            right: '5%',
            width: '35%'
          },
          item3: {
            img: '../img/projects/bebek/print2/H3-3_BEBEK_PK.png',
            top: '50%',
            left: '5%',
            right: 'auto',
            width: '35%'
          },
          item4: {
            img: '../img/projects/bebek/print2/H3-4_BEBEK_PK.png',
            top: '50%',
            left: 'auto',
            right: '5%',
            width: '35%'
          },
        }
      }
    ]
  },

  //Boatsetter web
  {
    client: 'Boatsetter',
    type: 'Web',
    tag: 'web',
    slug: 'boatsetter-web',
    spacing: 'space',
    visit: 'https://boatsetter.com/',
    logo: "../img/projects/boatsetter/boatsetter.svg",
    thumb: "../img/projects/boatsetter/web/bs-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/boatsetter/web/H1_BS_WEB.jpg",
    top: "dark",
    intro: 'Boatsetter is a boat rental company that allows individuals to connect with boat owners and captains to embark on your water experience. Our team of UX/UI experts collaborated with our engineers and developers to generate complex corporate websites, landing pages, dashboards and some design explorations for Boatsetter’s first app.',
    images: [
      {
        img: '../img/projects/boatsetter/web/H2_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H3_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H4_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H5_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H6_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H7_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H8_BS_WEB.jpg',
      },
      {
        img: '../img/projects/boatsetter/web/H9_BS_WEB.jpg',
      }
    ]
  },

  // Integra web
  {
    client: 'Integra',
    type: 'Web',
    tag: 'web',
    slug: 'integra-web',
    spacing: 'space',
    visit: 'https://integraad.com/',
    logo: "../img/projects/integra/integra.svg",
    thumb: "../img/projects/integra/web/integra-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/integra/web/H1_INTEGRA_WEB.jpg",
    top: "dark",
    intro: 'Integra combines communication, learning and technology into creative solutions that will engage employees toward business objectives. MediaWorx was in charge of creating the brand identity, website, presentations, and much more.',
    images: [
      {
        img: '../img/projects/integra/web/H2_INTEGRA_WEB.jpg',
      },
      {
        img: '../img/projects/integra/web/H3_INTEGRA_WEB.jpg',
      },
      {
        parallax: {
          height: '190vw',
          maxHeight: '2600px',
          item1: {
            img: '../img/projects/integra/web/H4-1_INTEGRA_WEB.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '52%'
          },
          item2: {
            img: '../img/projects/integra/web/H4-2_INTEGRA_WEB.jpg',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '52%'
          }
        }
      },
      {
        img: '../img/projects/integra/web/H5_INTEGRA_WEB.jpg',
      }
    ]
  },  

  //Bebek web
  {
    client: 'Bebek',
    type: 'Web',
    tag: 'web',
    slug: 'bebek-web',
    spacing: 'space',
    visit: 'http://bebekbabyproducts.com/',
    logo: "../img/projects/bebek/bebek.svg",
    thumb: "../img/projects/bebek/web/bebek-web.jpg",
    thumbColor: "light",
    span: "r1",
    cover: "../img/projects/bebek/web/H1_BEBEK_WEB.jpg",
    top: "light",
    intro: 'Bebēk focuses on creating simple, effective, and beautiful feeding products that combine cutting edge technology and superior designs. We created the product designs, 3D renders, packaging, video, and website achieving a high-tech and modern European look and feel.',
    images: [
      {
        img: '../img/projects/bebek/web/H2_BEBEK_WEB.jpg',
      },
      {
        img: '../img/projects/bebek/web/H3_BEBEK_WEB.jpg',
      },
      {
        parallax: {
          height: '80vw',
          maxHeight: '1550px',
          item1: {
            img: '../img/projects/bebek/web/H4-1_BEBEK_WEB.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '52%'
          },
          item2: {
            img: '../img/projects/bebek/web/H4-2_BEBEK_WEB.jpg',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '52%'
          }
        }
      }
    ],
    banner: '../img/projects/bebek/web/H5_BEBEK_WEB.jpg'
  },

  //ATT web
  {
    client: 'ATT',
    type: 'Web',
    tag: 'web',
    slug: 'att-web',
    spacing: 'space',
    visit: 'https://www.myamericantopteam.com/',
    logo: "../img/projects/att/att.png",
    thumb: "../img/projects/att/web/att-web.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/att/web/H1_ATT_WEB.jpg",
    top: "dark",
    intro: 'American Top Team provides martial art classes for all ages that allows everyone to be able to grow and learn at their own pace. Our team of skilled programmers and designers developed a parent website for American Top Team and child websites for different locations. Through API, we integrated ATT’s website with third-party applications to create a complete solution.',
    images: [
      {
        img: '../img/projects/att/web/H2_ATT_WEB.jpg',
      },
      {
        img: '../img/projects/att/web/H3_ATT_WEB.jpg',
      },
      {
        parallax: {
          height: '90vw',
          maxHeight: '1550px',
          item1: {
            img: '../img/projects/att/web/H4-1_ATT_WEB.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/att/web/H4-2_ATT_WEB.jpg',
            top: '10%',
            left: 'auto',
            right: '2rem',
            width: '45%'
          }
        }
      }
    ],
    banner: '../img/projects/att/web/H5_ATT.jpg'
  },

  //RV web
  {
    client: 'RV Rosales',
    type: 'Web',
    tag: 'web',
    slug: 'rv-web',
    spacing: 'space',
    visit: 'https://rvrosales.mx/',
    logo: "../img/projects/rvrosales/H1-LOGO_RV-R.png",
    logoPos: "top",
    thumb: "../img/projects/rvrosales/web/THUMNBAILS_MW_2019_RV-ROSALES1.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/rvrosales/web/H1_RV-R.jpg",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/rvrosales/web/H2_RV-R.jpg',
      },
      {
        img: '../img/projects/rvrosales/web/H3_RV-R.jpg',
      },
      {
        parallax: {
          height: '150vw',
          maxHeight: '1600px',
          item1: {
            img: '../img/projects/rvrosales/web/H4-1_RV-R.png',
            top: 0,
            left: '0rem',
            right: 'auto',
            width: '58%'
          },
          item2: {
            img: '../img/projects/rvrosales/web/H4-2_RV-R.png',
            top: '10%',
            left: 'auto',
            right: '0rem',
            width: '58%'
          }
        }
      }

    ],
    banner: '../img/projects/rvrosales/web/H5_RV-R.jpg'
  },  

  //NOI Cafe print
  {
    client: 'NOI Caffé',
    type: 'Print',
    tag: 'print',
    slug: 'noi-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/noi/noi.svg",
    thumb: "../img/projects/noi/print/noi-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/noi/print/H1_NOI.jpg",
    top: "dark",
    intro: 'NOI Caffé is a coffee company expert in gourmet coffee that provides state-of-the-art gourmet coffee machines and high-quality products. We designed the coffee vending machine signage, menus, catalogs, postcards, posters, and flyers. We also created all signage and advertisement for NOI Caffé at Walgreens locations.',
    images: [

      {
        parallax: {
          height: '60vw',
          maxHeight: '1250px',
          item1: {
            img: '../img/projects/noi/print/H2-1_NOI.jpg',
            top: '0',
            left: '0',
            right: 'auto',
            width: '30%'
          },
          item2: {
            img: '../img/projects/noi/print/H2-2_NOI.jpg',
            top: '0',
            left: '35%',
            right: 'auto',
            width: '30%'
          },
          item3: {
            img: '../img/projects/noi/print/H2-3_NOI.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '30%'
          }
        }
      },
      {
        img: '../img/projects/noi/print/H3_NOI.jpg',
      },
      {
        img: '../img/projects/noi/print/H4_NOI.jpg',
      },
      {
        parallax: {
          height: '100vw',
          maxHeight: '1650px',
          item1: {
            img: '../img/projects/noi/print/H5-1_NOI.png',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/noi/print/H5-2_NOI.jpg',
            top: '50%',
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item3: {
            img: '../img/projects/noi/print/H5-3_NOI.jpg',
            top: 0,
            left: 'auto',
            right: '0',
            width: '45%'
          },
          item4: {
            img: '../img/projects/noi/print/H5-4_NOI.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '45%'
          },
        }
      }

    ],
    banner: '../img/projects/noi/print/H6_NOI.jpg'
  },

  //Marriot etools
  {
    client: 'Marriott',
    type: 'eTools',
    tag: 'etools',
    slug: 'marriott-etools',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/marriott/marriott.png",
    thumb: "../img/projects/marriott/etools/marriot-etools.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/marriott/etools/H1_MARRIOTT_ETOOLS.jpg",
    top: "dark",
    intro: 'Marriott is an American hospitality company that manages and franchises an extensive portfolio of hotels and related lodging facilities. MediaWorx was hired by Marriott for an important mission: create a solution that will allow them to visualize, categorize, organize, collaborate and share all marketing initiatives in America.',
    images: [
      {
        img: '../img/projects/marriott/etools/H2_MARRIOTT_ETOOLS.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/marriott/etools/H3-1_MARRIOTT_ETOOLS.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/marriott/etools/H3-2_MARRIOTT_ETOOLS.jpg',
            top: '50%',
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item3: {
            img: '../img/projects/marriott/etools/H3-3_MARRIOTT_ETOOLS.jpg',
            top: 0,
            left: 'auto',
            right: '0',
            width: '45%'
          },
          item4: {
            img: '../img/projects/marriott/etools/H3-4_MARRIOTT_ETOOLS.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '45%'
          },
        }
      },
      {
        img: '../img/projects/marriott/etools/H6_MARRIOTT_ETOOLS.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/marriott/etools/H5-1_MARRIOTT_ETOOLS.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/marriott/etools/H5-2_MARRIOTT_ETOOLS.jpg',
            top: '50%',
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item3: {
            img: '../img/projects/marriott/etools/H5-3_MARRIOTT_ETOOLS.jpg',
            top: 0,
            left: 'auto',
            right: '0',
            width: '45%'
          },
          item4: {
            img: '../img/projects/marriott/etools/H5-4_MARRIOTT_ETOOLS.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '45%'
          },
        }
      },
    ],
    banner: '../img/projects/marriott/etools/H6_MARRIOTT_ETOOLS.jpg'
  },


  //Cirro brand
  {
    client: 'Cirro International',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'cirro-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/cirro/cirro.svg",
    thumb: "../img/projects/cirro/brand/cirro-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/cirro/brand/H1_CIRRO.jpg",
    top: "dark",
    intro: 'Cirro international is an IT company that provides customers with the technology that makes sense for their business. Through our creative team of graphic designers, we developed Cirro International’s brand identity focusing on a high-tech, modern look.',
    images: [
      {
        img: '../img/projects/cirro/brand/H2_CIRRO.jpg',
      },
      {
        img: '../img/projects/cirro/brand/H3_CIRRO.jpg',
      },
      {
        img: '../img/projects/cirro/brand/H4_CIRRO.jpg',
      }
    ],
    banner: '../img/projects/cirro/brand/H5_CIRRO.jpg'
  },

  //ChartFX brand
  {
    client: 'ChartFX',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'chartfx-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/chartfx/chartfx.svg",
    thumb: "../img/projects/chartfx/brand/THUMNBAILS_MW_2019_CHARTFX1.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/chartfx/brand/H1-CHARTFX.jpg",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/chartfx/brand/H2-LOGO-CHARTFX.svg',
      },
      {
        img: '../img/projects/chartfx/brand/H3-CHARTFX.jpg',
      },
      {
        img: '../img/projects/chartfx/brand/H4-CHARTFX.jpg',
      },
      {
        img: '../img/projects/chartfx/brand/H5-CHARTFX.jpg',
      },
      {
        img: '../img/projects/chartfx/brand/H6-CHARTFX.jpg',
      }
    ],
    banner: '../img/projects/chartfx/brand/H7-CHARTFX.jpg'
  },  

  //PLATUNE brand
  {
    client: 'PLATUNE',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'platune-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/PLATUNE/H1-LOGO-PLATUNE.svg",
    thumb: "../img/projects/PLATUNE/brand/THUMNBAILS_MW_2019_PLATUNE1.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/PLATUNE/brand/H1-PLATUNE.jpg",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/PLATUNE/brand/H2-LOGO-PLATUNE.svg',
      },
      {
        img: '../img/projects/PLATUNE/brand/H3-PLATUNE.jpg',
      },
      {
        img: '../img/projects/PLATUNE/brand/H4-PLATUNE.jpg',
      },
      {
        img: '../img/projects/PLATUNE/brand/H5-PLATUNE.jpg',
      },
      {
        img: '../img/projects/PLATUNE/brand/H6-PLATUNE.jpg',
      }
    ],
    banner: '../img/projects/PLATUNE/brand/H7-PLATUNE.jpg'
  },

  //PLATUNE brand
  {
    client: 'PIKAMIEL',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'PIKAMIEL-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/pikamiel/brand/LOGO_PIKAMIEL_BRAND-ID.svg",
    thumb: "../img/projects/pikamiel/brand/THUMNBAILS_MW_THUMB.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/pikamiel/brand/H1_PIKAMIEL_HERO.png",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/pikamiel/brand/LOGO_PIKAMIEL_BRAND-ID.svg',
      },
      {
        img: '../img/projects/pikamiel/brand/H2_PIKAMIEL_BRAND-ID.jpg',
      },
      {
        img: '../img/projects/pikamiel/brand/H3_PIKAMIEL_BRAND-ID.jpg',
      },
      {
        img: '../img/projects/pikamiel/brand/H4_PIKAMIEL_BRAND-ID.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '800px',
          item1: {
            img: '../img/projects/pikamiel/brand/H6a_PIKAMIEL_BRAND-ID.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: 'img/projects/pikamiel/brand/H6b_PIKAMIEL_BRAND-ID.jpg',
            top: 0,
            left: 'auto',
            right: 0,
            width: '35%'
          }
        }
      },
      {
        img: '../img/projects/pikamiel/brand/H7_PIKAMIEL_BRAND-ID.jpg',
      }
    ],
    banner: '../img/projects/pikamiel/brand/BANNER_PIKAMIEL_BRAND-ID.jpg'
  },  

  //STOCKDIO brand
  {
    client: 'STOCKDIO',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'STOCKDIO-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/STOCKDIO/H1-LOGO_STOCKDIO.svg",
    thumb: "../img/projects/STOCKDIO/brand/THUMNBAILS_MW_2019_STOCKDIO1.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/STOCKDIO/brand/H1-STOCKDIO.jpg",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/STOCKDIO/brand/H2-1-LOGO_STOCKDIO.svg',
      },
      {
        img: '../img/projects/STOCKDIO/brand/H3_STOCKDIO.jpg',
      },
      {
        img: '../img/projects/STOCKDIO/brand/H4_STOCKDIO.jpg',
      },
      {
        img: '../img/projects/STOCKDIO/brand/H5_STOCKDIO.jpg',
      }
    ],
    banner: '../img/projects/STOCKDIO/brand/H6_STOCKDIO.jpg'
  },     

  //WITLIST brand
  {
    client: 'WITLIST',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'WITLIST-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/WITLIST/H1-LOGO_WITLIST.svg",
    logoPos: "left",
    thumb: "../img/projects/WITLIST/brand/THUMNBAILS_MW_2019_WITLIST1.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/WITLIST/brand/H1-WITLIST.jpg",
    top: "dark",
    intro: '',
    images: [
      {
        img: '../img/projects/WITLIST/brand/H2-1-LOGO_WITLIST.svg',
      },
      {
        img: '../img/projects/WITLIST/brand/H3-WITLIST.jpg',
      },
      {
        img: '../img/projects/WITLIST/brand/H4-WITLIST.jpg',
      },
      {
        img: '../img/projects/WITLIST/brand/H5-WITLIST.jpg',
      },
      {
        img: '../img/projects/WITLIST/brand/H6-WITLIST.jpg',
      }
    ],
    banner: '../img/projects/WITLIST/brand/h7-WITLIST.jpg'
  },     

  //Rig brand
  {
    client: 'Rigsquared',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'rig-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/rig/rig.svg",
    thumb: "../img/projects/rig/brand/rig-brand.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/rig/brand/H1_R2_BIB.jpg",
    top: "light",
    intro: 'Rigsquared is a turnkey cryptocurrency mining software that allows gamers to easily mine cryptocurrency and without any prior mining expertise. It was a fun and challenging project to create Rigsquared’s brand identity from scratch.',
    images: [
      {
        img: '../img/projects/rig/brand/H2_R2_BIB.jpg',
      },
      {
        img: '../img/projects/rig/brand/H3_R2_BIB.jpg',
      },
      {
        img: '../img/projects/rig/brand/H4_R2_BIB.jpg',
      },
      {
        img: '../img/projects/rig/brand/H5_R2_BIB.jpg',
      },
      {
        img: '../img/projects/rig/brand/H6_R2_BIB.jpg',
      },
      {
        img: '../img/projects/rig/brand/H7_R2_BIB.jpg',
      },

    ],
    banner: '../img/projects/rig/brand/H8_R2_BIB.jpg'
  },

  //Momo print
  {
    client: 'Momo',
    type: 'Print',
    tag: 'print',
    slug: 'momo-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/momo/momo.png",
    thumb: "../img/projects/momo/print/momo-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/momo/print/H1_MOMO.jpg",
    top: "dark",
    intro: 'Momo, headquartered in Milan, Italy, brings to the market the finest Italian style to the automotive industry. We developed and designed the packaging for several car accessories that pop out on the shelves at stores.',
    images: [
      {
        parallax: {
          height: '120vw',
          maxHeight: '1000px',
          item1: {
            img: '../img/projects/momo/print/H2-1_MOMO.png',
            top: 0,
            left: '0',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/momo/print/H2-2_MOMO.png',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '60%'
          }
        }
      }
    ]
  },

  //IME Print
  {
    client: 'IME',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'ime-brand',
    spacing: 'no-space',
    visit: '',
    logo: "../img/projects/ime/ime.svg",
    thumb: "../img/projects/ime/brand/ime-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/ime/brand/H1_IME.jpg",
    top: "dark",
    intro: 'IME is a company that provides a turnkey U.S. market entry solution through a holistic strategy and hands-on execution plan for market growth. We created a solid yet modern brand identity for Integral Marketing Engineers that reflects professionalism and expertise.',
    images: [
      {
        img: '../img/projects/ime/brand/H0_IME.jpg',
      },
      {
        img: '../img/projects/ime/brand/H2_IME.jpg',
      },
      {
        img: '../img/projects/ime/brand/H3_IME.jpg',
      },
      {
        img: '../img/projects/ime/brand/H4_IME.jpg',
      },
      {
        img: '../img/projects/ime/brand/H5_IME.jpg',
      },

    ],
    banner: '../img/projects/ime/brand/H6_IME.jpg'
  },

  //Rig web
  {
    client: 'Rigsquared',
    type: 'Web',
    tag: 'web',
    slug: 'rig-web',
    visit: 'https://www.rigsquared.com/',
    spacing: 'space',
    logo: "../img/projects/rig/rig.svg",
    thumb: "../img/projects/rig/web/rig-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/rig/web/rig-cover.jpg",
    top: "light",
    intro: 'Rigsquared is a turnkey cryptocurrency mining software that allows gamers to easily mine cryptocurrency and without any prior mining expertise. Alongside our UX/UI team, engineers, and developers, we developed a modern and interactive website that is user-friendly and appealing to both gamers and miners.',
    images: [
      {
        img: '../img/projects/rig/web/rig1.jpg',
      },
      {
        img: '../img/projects/rig/web/rig2.jpg',
      },
      {
        img: '../img/projects/rig/web/rig3.jpg',
      },
    ],
    banner: '../img/projects/rig/web/rig-banner.jpg'
  },

   //Ocean Bank web
  {
    client: 'Ocean Bank',
    type: 'Web',
    tag: 'web',
    slug: 'oceanbank-web',
    visit: ' https://www.oceanbank.com/creditcard/index.html',
    spacing: 'space',
    logo: "../img/projects/oceanbank/oceanbank.svg",
    thumb: "../img/projects/oceanbank/web/ob-web.jpg",
    thumbColor: "light",
    span: "r1",
    cover: "../img/projects/oceanbank/web/H1_OBW.jpg",
    top: "light",
    intro: 'Ocean Bank is the largest independent state-chartered commercial bank headquartered in Florida. We developed several landing pages for digital marketing efforts for credit cards and checking accounts and gave the landing pages a fresh and modern look.',
    images: [
      {
        img: '../img/projects/oceanbank/web/H2_OBW.jpg',
        tag: ''
      },
      {
        img: '../img/projects/oceanbank/web/H3_OBW.jpg',
        tag: ''
      },
      {
         img: '../img/projects/oceanbank/web/H4_OBW.jpg',
        tag: ''
      },
      {
        img: '../img/projects/oceanbank/web/H5_OBW.jpg',
        tag: ''
      }
    ],
    banner: '../img/projects/oceanbank/web/H6_OBW.jpg'
  },

  //Powerful Yogurt print
  {
    client: 'Powerful Yogurt',
    type: 'Print',
    tag: 'print',
    slug: 'py-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/py/py.png",
    thumb: "../img/projects/py/print/py-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/py/print/H1_PY.jpg",
    top: "dark",
    intro: 'Powerful Yogurt is an active lifestyle brand whose focus is to provide consumers with products high in protein and made with all-natural ingredients. We developed several marketing pieces, social media strategies and implementations, sales and POP materials.',
    images: [
      {
        img: '../img/projects/py/print/H2_PY.jpg',
      },
      {
        img: '../img/projects/py/print/H3_PY.jpg',
      },
      {
        img: '../img/projects/py/print/H4_PY.jpg',
      },
      {
        img: '../img/projects/py/print/H5_PY.jpg',
      },
    ],
    banner: '../img/projects/py/print/H7_PY.jpg'
  },

  //ATT print
  {
    client: 'ATT',
    type: 'Print',
    tag: 'print',
    slug: 'att-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/att/att.png",
    thumb: "../img/projects/att/print/att-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/att/print/H1_ATT_PRINT.jpg",
    top: "dark",
    intro: 'American Top Team provides martial art classes for all ages that allows everyone to be able to grow and learn at their own pace. We designed a variety of printed pieces such as posters, business cards, postcards, shirts, flyers, stationaries, store interior signage, and more.',
    images: [
      {
        img: '../img/projects/att/print/H2_ATT_PRINT.jpg',
      },
      {
         parallax: {
          height: '90vw',
          maxHeight: '1450px',
          item1: {
            img: '../img/projects/att/print/H3-1_ATT_PRINT.png',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/att/print/H3-2_ATT_PRINT.png',
            top: 0,
            left: 'auto',
            right: 0,
            width: '50%'
          },
          item3: {
            img: '../img/projects/att/print/H3-3_ATT_PRINT.png',
            top: '30%',
            left: 0,
            right: 'auto',
            width: '40%'
          },
          item4: {
            img: '../img/projects/att/print/H3-4_ATT_PRINT.png',
            top: '35%',
            left: 'auto',
            right: 0,
            width: '60%'
          },
        }
      },
      {
        img: '../img/projects/att/print/H4_ATT_PRINT.jpg',
      },
      {
        img: '../img/projects/att/print/H5_ATT_PRINT.jpg',
      },
    ],
    banner: '../img/projects/att/print/H6_ATT_PRINT.jpg'
  },

  //Ymedia web
  {
    client: 'YMedia Vizeum',
    type: 'Web',
    tag: 'web',
    slug: 'ymedia-web',
    visit: 'https://www.ymedia.es',
    spacing: 'no-space',
    logo: "../img/projects/ymedia/ymedia.png",
    thumb: "../img/projects/ymedia/web/ymedia-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/ymedia/web/H1_YMEDIA.jpg",
    top: "dark",
    intro: 'Ymedia is a company based in Spain that offers integrated responses to communication needs and structured measurements of investment effectiveness. YMedia is one of our international clients and we were ecstatic when we were contacted to help them design 2 important projects: their new website and an interactive media analysis report. For the website, we had to create a disruptive template that works with dynamic data updated daily, also integrating several third-party platforms for content generation and newsletters automation. For the interactive media analysis report, we created a platform that explains all the media ecosystem in Spain that works both on desktop and mobile devices.',
    images: [
      {
        img: '../img/projects/ymedia/web/H2_YMEDIA.jpg',
        tag: ''
      },
      {
        parallax: {
          height: '115vw',
          maxHeight: '1650px',
          item1: {
            img: '../img/projects/ymedia/web/H3-1_YMEDIA.jpg',
            top: 0,
            left: 0,
            right: 'auto'
          },
          item2: {
            img: '../img/projects/ymedia/web/H3-2_YMEDIA.jpg',
            top: '10%',
            left: 'auto',
            right: '2rem'
          },
          item3: {
            img: '../img/projects/ymedia/web/H3-3_YMEDIA.jpg',
            top: '80%',
            left: 'auto',
            right: '2rem'
          }
        }
      },
      {
        img: '../img/projects/ymedia/web/H4_YMEDIA.jpg',
        tag: ''
      },
      {
        parallax: {
          height: '80vw',
          maxHeight: '1250px',
          item1: {
            img: '../img/projects/ymedia/web/H5-1_YMEDIA.jpg',
            top: 0,
            left: 0,
            right: 'auto'
          },
          item2: {
            img: '../img/projects/ymedia/web/H5-2_YMEDIA.jpg',
            top: '10%',
            left: 'auto',
            right: '2rem'
          },
          item3: {
            img: '../img/projects/ymedia/web/H5-3_YMEDIA.jpg',
            top: '40%',
            left: 'auto',
            right: '20vw'
          }
        }
      }
    ],
    banner: '../img/projects/ymedia/web/H6_YMEDIA.jpg'
  },

  //TQM web
  {
    client: 'Tequeño Mania',
    type: 'Web',
    tag: 'web',
    slug: 'tqm-web',
    visit: 'http://tqm.mwxdigital.com/',
    spacing: 'space',
    logo: "../img/projects/tqm/tqm.png",
    thumb: "../img/projects/tqm/web/tqm-web.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/tqm/web/H1_TQM.jpg",
    top: "dark",
    intro: 'Tequeño Mania is a bakery that manufactures Gourmet Tequeños for wholesale and retail using the best ingredients on the market. We helped Tequeño Mania with all of its franchising projects, creating strategy, content, landing page, catalog, and PDF forms to engage with prospects from the start to finish.',
    images: [
      {
        img: '../img/projects/tqm/web/H2_TQM.jpg',
        tag: ''
      },
      {
        img: '../img/projects/tqm/web/H3_TQM.jpg',
        tag: ''
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1850px',
          item1: {
            img: '../img/projects/tqm/web/H4-1_TQM.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/tqm/web/H4-2_TQM.jpg',
            top: '10%',
            left: 'auto',
            right: '2rem',
            width: '45%'
          }
        }
      },

    ],
    banner: '../img/projects/tqm/web/H5_TQM.jpg'
  },

  //SDF etools
  {
    client: 'SDF',
    type: 'eTools',
    tag: 'etools',
    slug: 'sdf-etools',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/sdf/sdf.svg",
    thumb: "../img/projects/sdf/etools/sdf-etools.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/sdf/etools/H1_SDF.jpg",
    top: "dark",
    intro: 'SDF is well known for being the only industrial plant in America that produces and commercializes dry spun acrylic fibers. We were hired by a Peruvian based multinational company to develop a multitier platform for their innovations department where they could visualize the innovation funnel process from start to finish, including feasibility, approvals, and KPIs control.',
    images: [
      {
        img: '../img/projects/sdf/etools/H2_SDF.jpg',
      },
      {
        parallax: {
          height: '100vw',
          maxHeight: '1250px',
          item1: {
            img: '../img/projects/sdf/etools/H3-1_SDF.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/sdf/etools/H3-2_SDF.jpg',
            top: '50%',
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item3: {
            img: '../img/projects/sdf/etools/H3-3_SDF.jpg',
            top: 0,
            left: 'auto',
            right: '0',
            width: '45%'
          },
          item4: {
            img: '../img/projects/sdf/etools/H3-4_SDF.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '45%'
          },
        }
      },
      {
        img: '../img/projects/sdf/etools/H5_SDF.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1000px',
          item1: {
            img: '../img/projects/sdf/etools/H6-1_SDF.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/sdf/etools/H6-2_SDF.jpg',
            top: '40%',
            left: 'auto',
            right: '0',
            width: '65%'
          }
        }
      }

    ]
  },

  //Locatel web
  {
    client: 'Locatel USA',
    type: 'Web',
    tag: 'web',
    slug: 'locatel-web',
    spacing: 'no-space',
    visit: 'http://locatelus.com/',
    logo: "../img/projects/locatel/locatel.svg",
    thumb: "../img/projects/locatel/web/locatel-web.jpg",
    thumbColor: "light",
    span: "r1",
    cover: "../img/projects/locatel/web/H1_LW.jpg",
    top: "dark",
    intro: 'Locatel is a pharmacy that retails a wide array of prestigious brands in medical equipment, pharmacy, optical, homeopathy, hearing aids, skincare, and maternity services. MediaWorx was hired to create a brand-new website for Locatel’s USA locations.',
    images: [
      {
        img: '../img/projects/locatel/web/H2_LW.jpg',
      },
      {
        img: '../img/projects/locatel/web/H3_LW.jpg',
      },
       {
        parallax: {
          height: '70vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/locatel/web/H4-1_LW.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/locatel/web/H4-2_LW.jpg',
            top: '10%',
            left: 'auto',
            right: '2rem',
            width: '45%'
          }
        }
      }

    ],
    banner: '../img/projects/locatel/web/H5_LW.jpg'
  },

  //Partners South brand
  {
    client: 'Partners South',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'partners-south-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/ps/ps.svg",
    thumb: "../img/projects/ps/brand/ps-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/ps/brand/H1_PS.jpg",
    top: "dark",
    intro: 'Partners South is a premier firm that specializes in asset and wealth protection.',
    images: [
      {
        img: '../img/projects/ps/brand/H2_PS.jpg',
      },
      {
        img: '../img/projects/ps/brand/H3_PS.jpg',
      },
      {
        img: '../img/projects/ps/brand/H4_PS.jpg',
      },
      {
        img: '../img/projects/ps/brand/H5_PS.jpg',
      },
      {
        img: '../img/projects/ps/brand/H6_PS.jpg',
      },

    ],
    banner: '../img/projects/ps/brand/H7_PS.jpg'
  },

  //HBO etools
  {
    client: 'HBO',
    type: 'eTools',
    tag: 'etools',
    slug: 'hbo-etools',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/hbo/hbo.png",
    thumb: "../img/projects/hbo/etools/hbo-etools.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/hbo/etools/H1_HBO.jpg",
    top: "dark",
    intro: 'HBO is an American premium cable and satellite television network that streams movies, series, documentaries, and sports anywhere at any time. HBO contacted us to create an e-tool where they could plan and track the performance of their marketing efforts while simultaneously control their budget on a multitier basis.',
    images: [
      {
        img: '../img/projects/hbo/etools/H2_HBO.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1000px',
          item1: {
            img: '../img/projects/hbo/etools/H3-1_HBO.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/hbo/etools/H3-2_HBO.jpg',
            top: '20%',
            left: 'auto',
            right: '0',
            width: '65%'
          }
        }
      },
      {
        img: '../img/projects/hbo/etools/H4_HBO.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1000px',
          item1: {
            img: '../img/projects/hbo/etools/H5-1_HBO.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/hbo/etools/H5-2_HBO.jpg',
            top: '20%',
            left: 'auto',
            right: '0',
            width: '65%'
          }
        }
      },
    ],
    banner: '../img/projects/hbo/etools/H6_HBO.jpg'
  },

  //La Torterie brand
  {
    client: 'La Torterie',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'torterie-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/torterie/torterie.png",
    thumb: "../img/projects/torterie/brand/torterie-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/torterie/brand/H1_LT.jpg",
    top: "dark",
    intro: 'La Torterie is a home-based catering business that specializes in homemade Gourmet Venezuelan desserts for different occasions. We developed the brand identity of La Torterie as well as the packaging for their homemade goods.',
    images: [
      {
        img: '../img/projects/torterie/brand/H2_LT.jpg',
      },
      {
        img: '../img/projects/torterie/brand/H3_LT.jpg',
      },
      {
        img: '../img/projects/torterie/brand/H4_LT.jpg',
      },
      {
        img: '../img/projects/torterie/brand/H5_LT.jpg',
      },
      {
        img: '../img/projects/torterie/brand/H6_LT.jpg',
      }
    ],
    banner: '../img/projects/torterie/brand/H7_LT.jpg'
  },

  //Caption Media brand
  {
    client: 'Caption Media',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'caption-media-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/caption/caption.png",
    thumb: "../img/projects/caption/brand/caption-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/caption/brand/H1_CM.jpg",
    top: "dark",
    intro: 'Caption Media Group is a company dedicated to providing closed captioning to US and Latin American markets. We designed Caption Media’s brand ID based on the concept of a TV and a dialog bubble combined creating a modern logo that accurately represents the services that Caption Media provides.',
    images: [
      {
        img: '../img/projects/caption/brand/H2_CM.jpg',
      },
      {
        img: '../img/projects/caption/brand/H3_CM.jpg',
      },
      {
        img: '../img/projects/caption/brand/H4_CM.jpg',
      },
      {
        img: '../img/projects/caption/brand/H5_CM.jpg',
      },
      {
        img: '../img/projects/caption/brand/H6_CM.jpg',
      },

    ],
    banner: '../img/projects/caption/brand/H7_CM.jpg'
  },

  //IME web
  {
    client: 'IME',
    type: 'Web',
    tag: 'web',
    slug: 'ime-web',
    spacing: 'space',
    visit: 'http://imedigital.com/',
    logo: "../img/projects/ime/ime-web.svg",
    thumb: "../img/projects/ime/web/ime-web.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/ime/web/H1_IME_WEB.jpg",
    top: "dark",
    intro: 'IME is a company that provides a turnkey U.S. market entry solution through a holistic strategy and hands-on execution plan for market growth. After developing their brand identity, we worked on their website showcasing IME’s services, methodology and corporate information.',
    images: [
      {
        img: '../img/projects/ime/web/H2_IME_WEB.jpg',
      },
       {
        img: '../img/projects/ime/web/H3_IME_WEB.jpg',
      },
      {
        parallax: {
          height: '115vw',
          maxHeight: '1700px',
          item1: {
            img: '../img/projects/ime/web/H4-1_IME_WEB.jpg',
            top: 0,
            left: '12%',
            right: 'auto',
            width: '40%'
          },
          item2: {
            img: '../img/projects/ime/web/H4-2_IME_WEB.jpg',
            top: '10%',
            left: 'auto',
            right: '10%',
            width: '40%'
          }
        }
      }
    ],


    banner: '../img/projects/ime/web/H5_IME_WEB.jpg'
  },

  //Rig etools
  {
    client: 'Rigsquared',
    type: 'App',
    tag: 'web',
    slug: 'rig-web2',
    spacing: 'space',
    visit: 'https://rigsquared.com/',
    logo: "../img/projects/rig/rig.svg",
    thumb: "../img/projects/rig/web2/rig-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/rig/web2/H1_WEB_R2.jpg",
    top: "dark",
    intro: 'Rigsquared is a turnkey cryptocurrency mining software that allows gamers to easily mine cryptocurrency and without any prior mining expertise. After completing their brand identity, we were hired to develop all UX/UI of an ecosystem of modular components: app, widget, installer, and wizard. Our main task? To develop a user-friendly cryptocurrency mining software for PC gamers.',
    images: [
      {
        img: '../img/projects/rig/web2/LOGO_WEB_R2.jpg',
      },
      {
        img: '../img/projects/rig/web2/H2_WEB_R2.jpg',
      },
       {
        img: '../img/projects/rig/web2/H3_WEB_R2.png',
      },
       {
        img: '../img/projects/rig/web2/H4_WEB_R2.jpg',
      },
       {
        img: '../img/projects/rig/web2/H5_WEB_R2.jpg',
      },
       {
        img: '../img/projects/rig/web2/H6_WEB_R2.jpg',
      },
    ],
    banner: '../img/projects/rig/web2/H7_WEB_R2.jpg'
  },

  //EL Agave azul brand
  {
    client: 'El Agave Azul',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'agave-azul-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/agave/agave-azul.png",
    thumb: "../img/projects/agave/brand/agave-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/agave/brand/H1_AA.jpg",
    top: "dark",
    intro: 'El Agave Azul restaurant specializes in catering and preparing dishes from authentic Mexican cuisine. We designed their brand ID based on an Agave plant and in the background, it displays a traditional Mexican symbol.',
    images: [
      {
        img: '../img/projects/agave/brand/H2_AA.jpg',
      },
      {
        img: '../img/projects/agave/brand/H3_AA.jpg',
      },
      {
        img: '../img/projects/agave/brand/H4_AA.jpg',
      },
      {
        parallax: {
          height: '100vw',
          maxHeight: '1750px',
          item1: {
            img: '../img/projects/agave/brand/H5-1_AA.png',
            top: 0,
            left: 0,
            right: 'auto',
            width: '55%'
          },
          item2: {
            img: '../img/projects/agave/brand/H5-2_AA.png',
            top: '20%',
            left: 'auto',
            right: '2rem',
            width: '60%'
          },
          item3: {
            img: '../img/projects/agave/brand/H5-3_AA.png',
            top: '50%',
            left: 'auto',
            right: '20rem',
            width: '70%'
          }
        }
      }

    ],
    banner: '../img/projects/agave/brand/H6_AA.jpg'
  },

  //Locatel App
  {
    client: 'Locatel USA',
    type: 'App',
    tag: 'web',
    slug: 'locatel-app',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/locatel/locatel.svg",
    thumb: "../img/projects/locatel/app/locatel-app.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/locatel/app/H1_LOCATEL_APP.jpg",
    top: "dark",
    intro: 'Locatel is a pharmacy that retails a wide array of prestigious brands in medical equipment, pharmacy, optical, homeopathy, hearing aids, skincare, and maternity services. We converted the traditional printed coupon book into an interactive mobile-friendly digital version.',
    images: [
      {
        img: '../img/projects/locatel/app/H2_LOCATEL_APP.jpg',
      },
      {
        img: '../img/projects/locatel/app/H3_LOCATEL_APP.jpg',
      },
      {
        img: '../img/projects/locatel/app/H4_LOCATEL_APP.jpg',
      }
    ],
    banner: '../img/projects/locatel/app/H5_LOCATEL_APP.jpg'
  },

  //Damca web
  {
    client: 'Damca',
    type: 'Web',
    tag: 'web',
    slug: 'damca-web',
    spacing: 'space',
    visit: 'http://www.damca.com/index.html',
    logo: "../img/projects/damca/damca.svg",
    thumb: "../img/projects/damca/web/damca-web.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/damca/web/H1_DAMCA.jpg",
    top: "dark",
    intro: 'DAMCA LOGISTICS company is a convenient global access point and getaway for international trade and commerce. For DAMCA we created their brand identity and once this was established, we designed and programmed their responsive website.',
    images: [
      {
        img: '../img/projects/damca/web/H2_DAMCA.jpg',
      },
      {
        img: '../img/projects/damca/web/H3_DAMCA.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/damca/web/H4-1_DAMCA.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '52%'
          },
          item2: {
            img: '../img/projects/damca/web/H4-2_DAMCA.jpg',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '52%'
          }
        }
      }
    ],
    banner: '../img/projects/damca/web/H5_DAMCA.jpg'
  },

  //Six o Clock brand
  {
    client: 'Six O Clock',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'sixoclock-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/sixoclock/sixoclock.svg",
    thumb: "../img/projects/sixoclock/brand/sixoclock-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/sixoclock/brand/H1_SOC.jpg",
    top: "dark",
    intro: 'Six O Clock is a concept that focuses on franchising coffee kiosks and mobile carts around the United States and Latin America.  We created Six O Clock’s brand identity focusing on giving it a look and feel of a contemporary coffee shop and its line of products.',
    images: [
      {
        img: '../img/projects/sixoclock/brand/LOGO_SOC.jpg',
      },
      {
        img: '../img/projects/sixoclock/brand/H2_SOC.jpg',
      },
      {
        img: '../img/projects/sixoclock/brand/H3_SOC.jpg',
      },
      {
        img: '../img/projects/sixoclock/brand/H4_SOC.jpg',
      },
      {
        img: '../img/projects/sixoclock/brand/H5_SOC.jpg',
      },
    ],
    banner: '../img/projects/sixoclock/brand/H6_SOC.jpg'
  },


  //Ocean Bank print
  {
    client: 'Ocean Bank',
    type: 'Print',
    tag: 'print',
    slug: 'oceanbank-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/oceanbank/oceanbankprint.svg",
    thumb: "../img/projects/oceanbank/print/oceanbank-print.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/oceanbank/print/H1_OBP.jpg",
    top: "dark",
    intro: 'Ocean Bank is the largest independent state-chartered commercial bank headquartered in Florida. MediaWorx’s team of creative graphic designers created billboards, brochures, displays, and many other print pieces.',
    images: [
      {
        img: '../img/projects/oceanbank/print/H2_OBP.jpg',

      },
      {
        img: '../img/projects/oceanbank/print/H3_OBP.jpg',
      },
      {
        img: '../img/projects/oceanbank/print/H4_OBP.jpg',
      },
      {
        img: '../img/projects/oceanbank/print/H5_OBP.jpg',
      },
      {
        img: '../img/projects/oceanbank/print/H6_OBP.jpg',
      },
    ],
    banner: '../img/projects/oceanbank/print/H7_OBP.jpg'
  },

  //Intercontinental print
  {
    client: 'Intercontinental Bank',
    type: 'Print',
    tag: 'print',
    slug: 'intercontinental-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/intercontinental/intercontinental.svg",
    thumb: "../img/projects/intercontinental/print/intercontinental-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/intercontinental/print/H1_INTER.jpg",
    top: "dark",
    intro: 'Recently acquired by Sunstate Bank, Intercontinental Bank was a local financial institution in South Florida. We helped Intercontinental Bank design and create branch signage, marketing campaigns, and printed collateral.',
    images: [
      {
        img: '../img/projects/intercontinental/print/H2_INTER.jpg',
      },
      {
        img: '../img/projects/intercontinental/print/H3_INTER.jpg',
      },{
        img: '../img/projects/intercontinental/print/H4_INTER.jpg',
      },
      {
        img: '../img/projects/intercontinental/print/H5_INTER.jpg',
      },
      {
        img: '../img/projects/intercontinental/print/H6_INTER.jpg',
      }
    ],
    banner: '../img/projects/intercontinental/print/H7_INTER.jpg'
  },


  //Claro print
  {
    client: 'Claro',
    type: 'Print',
    tag: 'print',
    slug: 'claro-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/claro/claro.svg",
    thumb: "../img/projects/claro/print/claro-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/claro/print/H1_CLARO.jpg",
    top: "light",
    intro: 'Claro is a global technology services company that provides customizable solutions to help businesses evolve and adapt to constant change. We designed a variety of catalogs for the sales and training departments.',
    images: [
      {
        img: '../img/projects/claro/print/H2_CLARO.png',
      },
      {
        parallax: {
          height: '80vw',
          maxHeight: '1000px',
          item1: {
            img: '../img/projects/claro/print/H3-1_CLARO.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/claro/print/H3-2_CLARO.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      },
      {
        img: '../img/projects/claro/print/H4_CLARO.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '600px',
          item1: {
            img: '../img/projects/claro/print/H5-1_CLARO.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/claro/print/H5-2_CLARO.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      },
      {
        img: '../img/projects/claro/print/H6_CLARO.png',
      },
    ],
  },

  //VN brand
  {
    client: 'VN Property',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'vn-brand',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/vn/vn.svg",
    thumb: "../img/projects/vn/brand/vn-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/vn/brand/H1_VNP.jpg",
    top: "dark",
    intro: 'VN Property is a newly developed high-end property management company that operates in South Florida. We designed VN Property’s brand identity to be powerful, minimalistic and professional.',
    images: [
      {
        img: '../img/projects/vn/brand/H2_VNP.jpg',
      },
      {
        img: '../img/projects/vn/brand/H3_VNP.jpg',
      },
      {
        img: '../img/projects/vn/brand/H4_VNP.jpg',
      },
      {
        img: '../img/projects/vn/brand/H5_VNP.jpg',
      }
    ],
    banner: '../img/projects/vn/brand/H6_VNP.jpg'
  },

  //TFS web
  {
    client: 'Trust Field Services',
    type: 'Web',
    tag: 'web',
    slug: 'tfs-web',
    spacing: 'space',
    visit: 'https://www.trustfieldservices.net/',
    logo: "../img/projects/tfs/tfs.svg",
    thumb: "../img/projects/tfs/web/tfs-web.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/tfs/web/H1_TFS.jpg",
    top: "dark",
    intro: 'Trust Field Services is an award-winning property preservation and maintenance company that handles all types of preservation operations for homes and buildings. We had the privilege of designing and developing their first website even though they had been in business for several years.',
    images: [
      {
        img: '../img/projects/tfs/web/H2_TFS.jpg',
      },
      {
        img: '../img/projects/tfs/web/H3_TFS.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/tfs/web/H4-1_TFS.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '52%'
          },
          item2: {
            img: '../img/projects/tfs/web/H4-2_TFS.jpg',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '52%'
          }
        }
      }
    ],
    banner: '../img/projects/tfs/web/H5_TFS.jpg'
  },

  //Mercantil print
  {
    client: 'Mercantil Bank',
    type: 'Print',
    tag: 'print',
    slug: 'mercantil-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/mercantil/mercantil.svg",
    thumb: "../img/projects/mercantil/print/mercantil-print.jpg",
    thumbColor: "light",
    span: "r2",
    cover: "../img/projects/mercantil/print/H0_MB.jpg",
    top: "dark",
    intro: 'Mercantil is a financial institution based in Caracas Venezuela with branches in some parts of the United States. MediaWorx worked on the whole development of all internal corporate communications and external campaigns. We also worked on digital campaign pieces and email touchpoints.',
    images: [
      {
        img: '../img/projects/mercantil/print/H1_MB.png',
      },
       {
        img: '../img/projects/mercantil/print/H2_MB.png',
      },
       {
        img: '../img/projects/mercantil/print/H3_MB.jpg',
      },
       {
        img: '../img/projects/mercantil/print/H4_MB.jpg',
      },
       {
        img: '../img/projects/mercantil/print/H5_MB.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1850px',
          item1: {
            img: '../img/projects/mercantil/print/H6-1_MB.png',
            top: 0,
            left: 'auto',
            right: '0',
            width: '60%'
          },
          item2: {
            img: '../img/projects/mercantil/print/H6-2_MB.png',
            top: '20%',
            left: '0',
            right: 'auto',
            width: '70%'
          },
          item3: {
            img: '../img/projects/mercantil/print/H6-3_MB.png',
            top: '55%',
            left: '5%',
            right: 'auto',
            width: '110%'
          }
        }
      }
    ],
    banner: '../img/projects/mercantil/print/H7_MB.jpg'
  },

   //Alpina  print
  {
    client: 'Alpina',
    type: 'Print',
    tag: 'print',
    slug: 'alpina-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/alpina/alpina.svg",
    thumb: "../img/projects/alpina/print/alpina-print.jpg",
    span: "r1",
    thumbColor: "dark",
    cover: "../img/projects/alpina/print/H1_ALPINA.jpg",
    top: "dark",
    intro: 'Alpina foods provide a variety of wholesome products that are both nourishing to the body and delicious. We designed product 3D renders, advertising and marketing campaigns, vehicle and truck signage, billboards, sales sheets, amongst others.',
    images: [
      {
        parallax: {
          height: '50vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/alpina/print/H2-1_ALPINA.png',
            top: '0',
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/alpina/print/H2-2_ALPINA.png',
            top: '0',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      },
      {
        parallax: {
          height: '50vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/alpina/print/H3-1_ALPINA.jpg',
            top: '0',
            left: '2rem',
            right: 'auto',
            width: '55%'
          },
          item2: {
            img: '../img/projects/alpina/print/H3-2_ALPINA.jpg',
            top: '20%',
            left: 'auto',
            right: '2rem',
            width: '55%'
          }
        }
      },
      {
        img: '../img/projects/alpina/print/H4_ALPINA.jpg',
      },
      {
        img: '../img/projects/alpina/print/H5_ALPINA.jpg',
      },
      {
        parallax: {
          height: '40vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/alpina/print/H6-1_ALPINA.png',
            top: '0',
            left: 'auto',
            right: '0',
            width: '80%'
          },

          item2: {
            img: '../img/projects/alpina/print/H6-2_ALPINA.jpg',
            top: '30%',

            left: '0',
            right: 'auto',
            width: '50%'
          }
        }
      }

    ],
    banner: '../img/projects/alpina/print/H7_ALPINA.jpg'
  },

  //Bebek print
  {
    client: 'Bebek',
    type: 'Print',
    tag: 'print',
    slug: 'bebek-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/bebek/bebek.svg",
    thumb: "../img/projects/bebek/print/bebek-print.jpg",
    thumbColor: "light",
    span: "r1",
    cover: "../img/projects/bebek/print/H1_BEBEK_P.jpg",
    top: "light",
    intro: 'Bebēk focuses on creating simple, effective, and beautiful feeding products that combine cutting edge technology and superior designs. Once the rebranding was complete, we designed a series of marketing and sales printed materials.',
    images: [
      {
        img: '../img/projects/bebek/print/H2_BEBEK_P.jpg',
      },
      {
        img: '../img/projects/bebek/print/H3_BEBEK_P.jpg',
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '2000px',
          item1: {
            img: '../img/projects/bebek/print/H4-1_BEBEK_P.jpg',
            top: 0,
            left: '20%',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/bebek/print/H4-2_BEBEK_P.jpg',
            top: '30%',
            left: '20%',
            right: 'auto',
            width: '60%'
          },
          item3: {
            img: '../img/projects/bebek/print/H4-3_BEBEK_P.jpg',
            top: '70%',
            left: '20%',
            right: 'auto',
            width: '60%'
          },
        }
      }

    ],
    banner: '../img/projects/bebek/print/H5_BEBEK_P.jpg'
  },

  //EZ web
  {
    client: 'EZ',
    type: 'Web',
    tag: 'web',
    slug: 'ez-web',
    spacing: 'space',
    visit: 'http://eztechusa.net/',
    logo: "../img/projects/ez/ez.svg",
    thumb: "../img/projects/ez/web/ez-web.jpg",
    thumbColor: "light",
    span: "r1",
    cover: "../img/projects/ez/web/H1_EZ.jpg",
    top: "dark",
    intro: 'EZ Tech Smart Solutions is a technology company that specializes in providing the best tech solutions to your problems. First, we developed EZ’s brand identity including the name and general direction. Then we developed their website for both home and business lines of service.',
    images: [
      {
        img: '../img/projects/ez/web/H2_EZ.jpg',
      },
      {
        img: '../img/projects/ez/web/H3_EZ.jpg',
      },
      {
        parallax: {
          height: '80vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/ez/web/H4-1_EZ.jpg',
            top: 0,
            left: 0,
            right: 'auto',
            width: '52%'
          },
          item2: {
            img: '../img/projects/ez/web/H4-2_EZ.jpg',
            top: '10%',
            left: 'auto',
            right: '0',
            width: '52%'
          }
        }
      }
    ],
    banner: '../img/projects/ez/web/H5_EZ.jpg'
  },

  //B&M brand
  {
    client: 'B&M',
    type: 'Brand Id',
    tag: 'brand',
    slug: 'bm-brand',
    spacing: 'no-space',
    visit: '',
    logo: "../img/projects/bm/B&M.svg",
    thumb: "../img/projects/bm/brand/bm-brand.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/bm/brand/H1_B&M.jpg",
    top: "dark",
    intro: 'B&M is a company that specializes in creating franchise and business opportunities focusing on space optimization for retail areas. We designed B&M’s brand identity to be bold and solid yet modern and reflecting professionalism.',
    images: [
      {
        img: '../img/projects/bm/brand/H2_B&M.jpg',
      },
      {
        img: '../img/projects/bm/brand/H3_B&M.jpg',
      },
      {
        img: '../img/projects/bm/brand/H4_B&M.jpg',
      },
      {
        img: '../img/projects/bm/brand/H5_B&M.jpg',
      },
      {
        img: '../img/projects/bm/brand/H6_B&M.jpg',
      },

    ],
    banner: '../img/projects/bm/brand/H7_B&M.jpg'
  },

  //Marriott print
  {
    client: 'Marriott',
    type: 'Print',
    tag: 'print',
    slug: 'marriott-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/marriott/marriott.png",
    thumb: "../img/projects/marriott/print/marriott-print.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/marriott/print/H1_MP.jpg",
    top: "dark",
    intro: 'Marriott is an American hospitality company that manages and franchises an extensive portfolio of hotels and related lodging facilities. We helped Marriot with external and internal communications including the creative concept, communication strategy, and implementation for the CALA employees when they were transferring offices and needed a complete revamp.',
    images: [
      {
        parallax: {
          height: '50vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/marriott/print/H2-1_MP.jpg',
            top: 0,
            left: '0',
            right: 'auto',
            width: '30%'
          },
          item2: {
            img: '../img/projects/marriott/print/H2-2_MP.jpg',
            top: '0',
            left: '35.5%',
            right: 'auto',
            width: '30%'
          },
          item3: {
            img: '../img/projects/marriott/print/H2-3_MP.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '30%'
          }
        }
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1550px',
          item1: {
            img: '../img/projects/marriott/print/H3-1_MP.png',
            top: '20%',
            left: '0',
            right: 'auto',
            width: '60%'
          },
          item2: {
            img: '../img/projects/marriott/print/H3-3_MP.jpg',
            top: '0',
            left: 'auto',
            right: '5%',
            width: '50%'
          },
          item3: {
            img: '../img/projects/marriott/print/H3-2_MP.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '50%'
          }
        }
      },
      {
        img: '../img/projects/marriott/print/H4_MP.jpg',
      },
      {
        img: '../img/projects/marriott/print/H5_MP.jpg',
      },
      {
        parallax: {
          height: '60vw',
          maxHeight: '1550px',
          item1: {
            img: '../img/projects/marriott/print/H6-1_MP.jpg',
            top: '0',
            left: '0',
            right: 'auto',
            width: '30%'
          },
          item2: {
            img: '../img/projects/marriott/print/H6-2_MP.jpg',
            top: '0',
            left: '35.5%',
            right: 'auto',
            width: '30%'
          },
          item3: {
            img: '../img/projects/marriott/print/H6-3_MP.png',
            top: '0',
            left: 'auto',
            right: '0',
            width: '30%'
          }
        }
      }

    ],
    banner: '../img/projects/marriott/print/H7_MP.jpg'
  },

  //X10
  {
    client: 'Tequeño Mania',
    type: 'Print',
    tag: 'print',
    slug: 'tqm-print',
    spacing: 'space',
    visit: '',
    logo: "../img/projects/tqm/tqm.png",
    thumb: "../img/projects/tqm/print/tqm-print.jpg",
    thumbColor: "dark",
    span: "r1",
    cover: "../img/projects/tqm/print/H1_TQM_PRINT.jpg",
    top: "dark",
    intro: 'Tequeño Mania is a bakery that manufactures Gourmet Tequeños for wholesale and retail using the best ingredients on the market. Our team designed a variety of printed pieces, sales materials, POP signage, and franchise collaterals.',
    images: [
      {
        parallax: {
          height: '120vw',
          maxHeight: '1450px',
          item1: {
            img: '../img/projects/tqm/print/H2-1_TQM_PRINT.jpg',
            top: 0,
            left: '5%',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/tqm/print/H2-2_TQM_PRINT.jpg',
            top: '5%',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        },
      },
      {
        img: '../img/projects/tqm/print/H3_TQM_PRINT.jpg'
      },
      {
        img: '../img/projects/tqm/print/H4_TQM_PRINT.jpg'
      },
      {
        parallax: {
          height: '120vw',
          maxHeight: '1450px',
          item1: {
            img: '../img/projects/tqm/print/H5-1_TQM_PRINT.jpg',
            top: 0,
            left: '5%',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/tqm/print/H5-2_TQM_PRINT.jpg',
            top: '5%',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      }
    ]
  },

   //Amerant web
  {
    client: 'Amerant',
    type: 'Web',
    tag: 'web',
    slug: 'amerant-web',
    spacing: 'space',
    visit: 'https://www.amerantbank.com/digitalcd/',
    logo: "../img/projects/amerant/amerant.svg",
    thumb: "../img/projects/amerant/web/amerant-web.jpg",
    thumbColor: "dark",
    span: "r2",
    cover: "../img/projects/amerant/web/H1_DIGITAL_AMERANT.jpg",
    top: "dark",
    intro: 'Amerant is a financial institution dedicated to delivering personal and exceptional service to help you achieve your financial goals. We were actively involved in the rebranding process from Mercantil Bank to Amerant Bank. We implemented the new brand guidelines for many internal and external digital and printed communications and campaigns. We created part of the launch concept and implementation.',
    images: [
      {
        img: '../img/projects/amerant/web/H2_DIGITAL_AMERANT.jpg',
      },
      {
        parallax: {
          height: '70vw',
          maxHeight: '1150px',
          item1: {
            img: '../img/projects/amerant/web/H3-1_DIGITAL_AMERANT.jpg',
            top: 0,
            left: '5%',
            right: 'auto',
            width: '90%'
          },
          item2: {
            img: '../img/projects/amerant/web/H3-2_DIGITAL_AMERANT.jpg',
            top: '50%',
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item3: {
            img: '../img/projects/amerant/web/H3-3_DIGITAL_AMERANT.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '48%'
          }
        }
      },
      {
        parallax: {
          height: '110vw',
          maxHeight: '1350px',
          item1: {
            img: '../img/projects/amerant/web/H4-1_DIGITAL_AMERANT.jpg',
            top: '0',
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/amerant/web/H4-2_DIGITAL_AMERANT.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      },
      {
        img: '../img/projects/amerant/web/H5_DIGITAL_AMERANT.jpg',
      },
      {
        parallax: {
          height: '130vw',
          maxHeight: '1850px',
          item1: {
            img: '../img/projects/amerant/web/H6-1_DIGITAL_AMERANT.jpg',
            top: '30%',
            left: '0',
            right: 'auto',
            width: '45%'
          },
          item2: {
            img: '../img/projects/amerant/web/H6-2_DIGITAL_AMERANT.jpg',
            top: '0',
            left: 'auto',
            right: '0',
            width: '45%'
          },
          item3: {
            img: '../img/projects/amerant/web/H6-3_DIGITAL_AMERANT.jpg',
            top: '50%',
            left: 'auto',
            right: '0',
            width: '45%'
          }
        }
      },
    ]
  },

]

export default Projects