import React from 'react';

const Member = ({picture, name, role }) => {
  return (
    <div className="member move-up">
      <div className="member__picture">
        <img src={picture} alt=""/>
      </div>
      <h2 className="member__name">{name}</h2>
      <p className="member__role">{role}</p>
    </div>
  )
}

export default Member